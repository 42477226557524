import React from 'react';
import Chart from 'react-apexcharts';

const PieChart = ({ remainingBalance, originalBalance, width, height }) => {
    const options = {
        chart: {
            type: 'donut',
        },
        labels: ['Consumed Balance', 'Remaining Balance'],
        colors: ['#FD7545','#0078A3'],
        dataLabels: {
            enabled: false,
        },
        grid: {
            padding: {
                right: 50,
            },
        },
        legend: {
            show: false,
            position: 'bottom',
            horizontalAlign: 'center',
            verticalAlign: 'middle',
            itemMargin: {
                horizontal: 15,
                vertical: 10,
            },
        },
        plotOptions: {
            pie: {
                startAngle: 0,
                endAngle: 360,
                expandOnClick: true,
                offsetX: 0,
                offsetY: 0,
                customScale: 1,
                dataLabels: {
                    offset: 0,
                    minAngleToShowLabel: 10,
                },
                donut: {
                    size: '65%',
                    background: 'transparent',
                    
                },
            },
        },
    };

    const series = [originalBalance - remainingBalance, remainingBalance];

    return (
        <div className="pie-chart position-relative" >
            <Chart options={options} series={series} type="donut" width={width} height={height} />
            {/* <div className="position-absolute top-50 start-50 translate-middle text-start ms-n3">
                <span className="fs-4 fw-bold text-body">{formatLargeNumber(remainingBalance)}</span>
            </div> */}
        </div>
    );
};

export default PieChart;