import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { updateUser } from '../../../slices/UserManagement/UpdateUser/thunk';

export const handleModalAction = ({ 
    values, 
    status, 
    setLoading, 
    navigate, 
    userId, 
    dispatch, 
    toggleModal 
}) => {
    const phoneNumber = values.phone ? formatPhoneNumberIntl(values.phone) : '';
    const dialCodeWithPlus = phoneNumber.match(/^\+\d+(\s|$)/)?.[0];
    const restOfNumber = phoneNumber.slice(dialCodeWithPlus?.length);
    const mobileNumberWithoutSpaces = restOfNumber.replace(/\s/g, '');
    const dialCodeWithoutSpaces = dialCodeWithPlus.replace(/\s/g, '');

    const formattedValues = {
        userName: values?.username,
        role: values?.userrole?.value,
        email: values?.email,
        profile: {
            firstName: values?.firstname,
            lastName: values?.lastname,
        },
        phone: mobileNumberWithoutSpaces,
        dialCode: dialCodeWithoutSpaces,
        status: status.toLowerCase(),
    };

    dispatch(updateUser(setLoading, formattedValues, navigate, userId, false));
    toggleModal();
};
