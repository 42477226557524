import { Card, CardBody, Row, Col, Input } from "reactstrap";
import { LabelText } from "../Utils/Input";
import SelectField from "../Utils/SelectField";
import { Loader } from "../Utils/Loader";
import { useEffect, useState } from "react";
import { fetchAllOperatorDefaultConfigurations } from "../../slices/OperatorManagement/GetAllDefaultConfiguraions/thunk";
import { useDispatch, useSelector } from "react-redux";
import { camelize } from "./Constant/OperatorListingJson";
import { brmCheck, combinedConfigurations, customerRetrievalCheck, handleSelectChange, handleSwitchToggle, mapConfig } from "./Constant/ConfigFunction";
import { configDisplayNameMapping } from "./Constant/Options";

export default function ConfigurationForOperator({ setFinalValues, finalValues, values, configData, isEdit}) {
    const [loading, setLoading] = useState(false);
    const [switchState, setSwitchState] = useState({});
    const [selectState, setSelectState] = useState({});
    const [useEffectEnabled, setUseEffectEnabled] = useState(false);
    const dispatch = useDispatch();
    const isCreate = location.pathname.includes('create-operator')

    useEffect(() => {
        if (values?.Domain) {
            dispatch(fetchAllOperatorDefaultConfigurations(setLoading, values.Domain.label));
        }
    }, [dispatch, values?.Domain]);

    const OperatorDefaultConfigurationList = useSelector((state) => state.GetAllDefaultConfiguration.OperatorDefaultConfiguration);

    useEffect(() => {
        mapConfig(isEdit, configData, OperatorDefaultConfigurationList, setSwitchState, setSelectState)
    }, [OperatorDefaultConfigurationList, isEdit]);

    useEffect(() => {
        brmCheck(switchState, setSwitchState)
    }, [switchState?.TaskEnabled])

    useEffect(() => {
        customerRetrievalCheck(useEffectEnabled, isCreate, setSwitchState, values)
    }, [isCreate, values?.Domain,switchState?.CustomerSearch,switchState?.CustomerListing]);
    
    useEffect(() => {
        const combinedValues = {
            ...switchState,
            ...Object.fromEntries(
                Object.entries(selectState).map(([key, value]) => [key, value.value])
            )
        };
        setFinalValues(combinedValues);
    }, [switchState, selectState]);

    const configurationsWithSelectFields = ["identityManagement", "paymentManagement"];

    return (
        <div>
            <Card>
                <h5 className="px-3 pt-3">Configurations</h5>
                <hr className="m-0 mt-1 hr-width ms-3" />
                {loading && (<Loader />)}
                <CardBody>
                    <Row className="gy-4">
                        {combinedConfigurations(OperatorDefaultConfigurationList).map((config, configIndex) => (
                            <Row key={`${config.name}-${configIndex}`} className="mt-4">
                                <Col xxl={3} md={6}>
                                    <div className="d-flex flex-column">
                                        {config.name === "taskManagement"
                                            ? config.value.map((item, index) => (
                                                <div key={index} className={`d-flex align-items-center mb-4 ${index > 0 ? 'mt-4' : ''}`}>
                                                    <div className="me-3" style={{ width: '150px' }}>
                                                        <LabelText displayText={item.key === "TaskEnabled" ? "UMP Tasks" : "BRM Tasks"} />
                                                    </div>
                                                    <div className="d-flex align-items-center form-label" style={{ minWidth: '10px' }}>
                                                        <span className="mx-2">:</span>
                                                    </div>
                                                    <div className="form-check form-switch ms-5">
                                                        <Input
                                                            type="switch"
                                                            className="form-check-input form-label"
                                                            checked={switchState[item.key] || false}
                                                            disabled={!switchState["TaskEnabled"] && index !== 0}
                                                            onChange={() => handleSwitchToggle(item.key, setUseEffectEnabled, setSwitchState)}
                                                        />
                                                    </div>
                                                </div>
                                            ))
                                            : config.name === "customerRetrieval" ? config.value.map((item, index) => (
                                                <div key={index} className={`d-flex align-items-center mb-4 ${index > 0 ? 'mt-4' : ''}`}>
                                                    <div className="me-3" style={{ width: '150px' }}>
                                                        <LabelText displayText={item.key === "CustomerSearch" ? "Customer Search" : "Customer Listing"} />
                                                    </div>
                                                    <div className="d-flex align-items-center form-label" style={{ minWidth: '10px' }}>
                                                        <span className="mx-2">:</span>
                                                    </div>
                                                    <div className="form-check form-switch ms-5">
                                                        <Input
                                                            type="switch"
                                                            className="form-check-input form-label"
                                                            checked={switchState[item.key] || false}
                                                            onChange={() => handleSwitchToggle(item.key, setUseEffectEnabled, setSwitchState)}
                                                        />
                                                    </div>
                                                </div>
                                            )) : (
                                                <div className="d-flex align-items-center mb-3">
                                                    <div className="me-3" style={{ width: '150px' }}>
                                                        <LabelText displayText={configDisplayNameMapping[config.name] || config.name} />
                                                    </div>
                                                    <div className="d-flex align-items-center form-label" style={{ minWidth: '10px' }}>
                                                        <span className="mx-2">:</span>
                                                    </div>
                                                    <div className="form-check form-switch ms-5">
                                                        <Input
                                                            type="switch"
                                                            className="form-check-input form-label"
                                                            checked={switchState[config.name] || false}
                                                            onChange={() => handleSwitchToggle(config.name, setUseEffectEnabled, setSwitchState)}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </Col>
                                {switchState[config.name] && configurationsWithSelectFields.includes(config.name) && (
                                    config.value.map((item, itemIndex) => (
                                        <Col key={`${config.name}-${itemIndex}`} xxl={3} md={6} className=" mb-2">
                                            <div className=" ">
                                                {!(config.name === 'paymentManagement' && item.key === 'PaymentStatus') &&
                                                    !(config.name === 'identityManagement' && item.key === 'Enable') && (
                                                        <SelectField
                                                            name={item.key}
                                                            options={item.value.map(opt => ({ label: opt, value: opt }))}
                                                            value={selectState[item.key] || null}
                                                            handleChange={(selectedOption) => handleSelectChange(item.key, selectedOption, setSelectState)}
                                                            placeholder={
                                                                config.name === 'paymentManagement' ? 'Select Payment Provider' :
                                                                    config.name === 'identityManagement' ? 'Select Identity Provider' :
                                                                        'Select...'
                                                            }
                                                        />
                                                    )}
                                            </div>
                                        </Col>
                                    ))
                                )}
                            </Row>
                        ))}
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
}
