import moment from "moment";
import { termValidity } from "../../Pricings/Offers/constants/checkEmptyFields";


export const groupedFunction = (offerResponse, serviceResponse, resourceData, setResult, customerId, customerData, orderId) => {
    if (offerResponse.length > 0 && serviceResponse.length > 0) {
        const groupedResults = {
            baseOffers: {},
            addOnOffers: {}
        };

        offerResponse.forEach((offer) => {
            const matchingService = serviceResponse.find((service) => service.serviceCode === offer.serviceCode);
            let provAttr = [];
            if (matchingService) {
                serviceResponse.forEach((value, index) => {
                    if (offer.serviceCode === serviceResponse[index].serviceCode) {
                        provAttr.push(serviceResponse[index].provisioningAttributes);
                    }
                });
            }
            const serviceName = matchingService ? matchingService.name : '';
            const category = offer.addOnOffer ? 'addOnOffers' : 'baseOffers';
            if (!groupedResults[category][serviceName]) {
                groupedResults[category][serviceName] = [];
            }
            let chargeCard = []
            offer?.Charges?.forEach((charge)=>{
                resourceData?.forEach((resource)=>{
                    if(resource.resourceId === charge?.resourceId){
                        let jsonObj = {
                            currencySymbol: resource?.currencySymbol,
                            chargeAmount: charge?.amount,
                            oneTimeorRecurring: charge?.type === 1 ? "One Time" : "Recurring"
                        }
                        chargeCard.push(jsonObj)
                    }
                })
            })
            let grantCard = []
            offer?.Grants?.forEach((grant)=>{
                resourceData?.forEach((resource)=>{
                    if(resource.resourceId === grant?.resourceId){
                        let jsonObj = {
                            uom: resource?.unitofmeasurement,
                            name: resource?.name,
                            grantAmount: grant?.amount,
                            oneTimeorRecurring: grant?.type === 1 ? "One Time" : "Recurring"
                        }
                        grantCard.push(jsonObj)
                    }
                })
            })

            const validityFrom = moment(offer.validity.from).format('MMM DD, YYYY');
            const validityTo = moment(offer.validity.to).format('MMM DD, YYYY');
            const termValidityLabel = offer.subscriptionValidity && offer.subscriptionValidity.relativeEndTimeUnit
                ? `${termValidity(
                    offer.subscriptionValidity.relativeEndTimeUnit,
                    offer.subscriptionValidity.relativeEndTimeValue
                )?.props?.children[0]}${termValidity(
                    offer.subscriptionValidity.relativeEndTimeUnit,
                    offer.subscriptionValidity.relativeEndTimeValue
                )?.props?.children[1]}`
                : "Never Ending";
            const totalChargeAmount = offer.Charges ? offer.Charges.reduce((sum, charge) => sum + charge.amount, 0) : 0;
            const unitPrice = offer.Charges ? offer.Charges.reduce((sum, charge) => sum + charge.amount, 0) : 0;
            const subTotal = offer.Charges ? offer.Charges.reduce((sum, charge) => sum + charge.amount, 0) : 0;
            groupedResults[category][serviceName].push({
                offerCode: offer.offerCode,
                offerName: offer.offerName,
                validityFrom: validityFrom,
                validityTo: validityTo,
                termValidity: termValidityLabel,
                Grants: offer.Grants ? offer.Grants : [],
                Charges: offer.Charges ? offer.Charges : [],
                Usage: offer.usageRatePlan ? offer.usageRatePlan : [],
                serviceName: serviceName,
                provisioningAttributes: offer?.attributes ? offer?.attributes : [],
                totalChargeAmount: totalChargeAmount,
                unitPrice: unitPrice,
                subTotal: subTotal,
                description: offer.description,
                CustomerId: customerId,
                CustomerName: customerData?.firstName,
                orderId: orderId,
                categories: offer?.categories || [],
                ChargeCard: chargeCard,
                GrantCard: grantCard
            });
        });

        const result = {
            baseOffers: Object.entries(groupedResults.baseOffers),
            addOnOffers: Object.entries(groupedResults.addOnOffers)
        };

        setResult(result);
    }
}

export const handleSelectOffer = (selectedOffer, navigate, subscriptionId) => {
    navigate('/order-select', { state: { selectedOffer, subscriptionId } });
};

export const filteredResultFunction = (result, searchTerm) => {
    return result.filter(([serviceName]) =>
        serviceName.toLowerCase().includes(searchTerm.toLowerCase())
    );
}

export const toggleAccordion = (open, setOpen) => {
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    return toggle
}