import { addresource } from "../../../../slices/resources/addresources/thunk";
import { editResource } from '../../../../slices/resources/updateresource/thunk';

export const handleSubmit = ({
    values,
    aggregate,
    isChecked,
    isCounter,
    isEdit,
    resourceName,
    dispatch,
    setLoading,
    navigate,
}) => {
    const aggregateLabels = aggregate && values?.aggregateResource
        ? values.aggregateResource.map(resource => resource.label)
        : [];

    const thresholdArray = values.thresholds?.map((threshold) => {
      const isPercentage = threshold.thresholdType !== "Percentage" ? 0 :1;
      const isFixed = threshold.thresholdType !== "Fixed" ? 0 :1;
      if (isPercentage || isFixed) {
        return {
          thresholdType: isPercentage ? 'Percentage' : 'Fixed',
          thresholdValue: threshold.thresholdValue !==''? parseInt(threshold.thresholdValue) : null,
        };
      }
    }).filter(threshold => threshold.thresholdValue !== null) || [];

    const jsonObj = JSON.stringify({
        ...(aggregate !== true && { isCurrency: values?.isCurrency?.value }),
        name: values?.name,
        resourceId: values?.resourceId,
        ...(values?.isCurrency?.value && { currencyCode: values?.currencyCode?.value }),
        ...(aggregate !== true && {
            ceiling: parseInt(values?.ceiling) || null,
            floor: parseInt(values?.floor) || null,
        }),
        unitofmeasurement: values?.uom?.value,
        consumptionRule: values?.consumptionRule?.value,
        override: isChecked,
        isCounter: isCounter,
        isAggregate: aggregate,
        ...(aggregateLabels.length > 0 && { aggregates: aggregateLabels }),
        ...(thresholdArray.length > 0 && { threshold: thresholdArray }),
    });

    if (!isEdit) {
        dispatch(addresource(jsonObj, setLoading, navigate));
    } else {
        dispatch(editResource(jsonObj, resourceName, setLoading, navigate));
    }
};

export const getConsumptionRule = (value) => {
    switch (value) {
        case "EST":
            return "Earliest Start Time";
        case "LST":
            return "Latest Start Time";
        case "EET":
            return "Earliest Expiration Time";
        case "LET":
            return "Latest Expiration Time";
        case "ESTLET":
            return "Earliest Start Time and Latest Expiration Time";
        case "ESTEET":
            return "Earliest Start Time and Earliest Expiration Time";
        case "LSTEET":
            return "Latest Start Time and Earliest Expiration Time";
        case "LSTLET":
            return "Latest Start Time and Latest Expiration Time";
        case "EETEST":
            return "Earliest Expiration Time and Earliest Start Time";
        case "EETLST":
            return "Earliest Expiration Time and Latest Start Time";
        case "LETEST":
            return "Latest Expiration Time and Earliest Start Time";
        case "LETLST":
            return "Latest Expiration Time and Latest Start Time";
        default:
            return "-"
    }
}