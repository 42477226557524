import moment from "moment";
import { setValueForSubValEndsOn, setValueForSubValStartsOn } from "./displayInfinite";



export const handleChangeForSubscriptionValidity = (initialValues, setFieldValue, startOrEnd) => (selectedValue) => {
    if (startOrEnd === "Start") {
        setValueForSubValStartsOn(initialValues, setFieldValue, selectedValue)
    }
    else {
        setValueForSubValEndsOn(initialValues, setFieldValue, selectedValue)
    }
}

export const categoryOptions = (Offers) => {
    let categories = []
    Offers.forEach((offer) => {
        if (offer?.categories) {
            offer.categories.forEach((category) => {
                if (!categories.some((item) => item.value === category)) {
                    categories.push({ label: category, value: category });
                }
            });
        }
    });
    return categories
}

export const handleCategoryChange = (value,setFilteredBaseOffers,baseOffers,isBaseCategory,setSelectedBaseCategories,setSelectedAddOnCategories) => {
    if (isBaseCategory) {
        setSelectedBaseCategories([...value]);
      } else {
        setSelectedAddOnCategories([...value]);
      }
    let selectedCategories = value.map((val) => val.value);
    if (!selectedCategories.length) {
        setFilteredBaseOffers(baseOffers);
    } else {
        const filtered = baseOffers.filter((offer) => {
            const offerCategories = offer.categories || [];
            return offerCategories.some((category) => selectedCategories.includes(category));
        });
        setFilteredBaseOffers(filtered);
    }
};