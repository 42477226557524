export const addThreshold = (thresholds, setThresholds, activeButtonIndex, setActiveButtonIndex, setFieldValue) => {
    const newThreshold = {
      thresholdType: "Fixed",
      thresholdValue: '',
    };
    const updatedThresholds = [...thresholds, newThreshold];
    setThresholds(updatedThresholds);
    setActiveButtonIndex([...activeButtonIndex, 0]);
    setFieldValue('thresholds', updatedThresholds); 
  };
  
export const removeThreshold = (index, thresholds, setThresholds, activeButtonIndex, setActiveButtonIndex, setFieldValue, values) => {
    setThresholds(thresholds.filter((_, i) => i !== index));
    setActiveButtonIndex(activeButtonIndex.filter((_, i) => i !== index));
    if (values.thresholds) {
        const updatedThresholds = values.thresholds.filter((_, i) => i !== index);

        setFieldValue('thresholds', updatedThresholds);
    }
};

export const handleThresholdChange = (index, field, value, thresholds, setThresholds,setFieldValue) => {
    const updatedThresholds = [...thresholds];
    updatedThresholds[index] = {
      ...updatedThresholds[index],
      [field]: value,
    };
    setThresholds(updatedThresholds);
    setFieldValue('thresholds', updatedThresholds);
  };

  export const toggleButton = (index, value, activeButtonIndex, setActiveButtonIndex, thresholds, setThresholds, setFieldValue) => {
    const updatedActiveButtonIndex = [...activeButtonIndex];
    updatedActiveButtonIndex[index] = value;
    setActiveButtonIndex(updatedActiveButtonIndex);
  
    const updatedThresholds = [...thresholds];
    updatedThresholds[index] = {
      ...updatedThresholds[index],
      thresholdType: value === 0 ? "Fixed" : "Percentage",
      thresholdValue: "",
    };
  
    setThresholds(updatedThresholds);
    setFieldValue('thresholds', updatedThresholds);
  };
  