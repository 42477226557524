import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import InputField from '../../Utils/InputField';

const ThresholdNotification = ({
  thresholds,
  addThreshold,
  toggleButton,
  handleThresholdChange,
  removeThreshold,
  handleChange,
  touched,
  errors,
  values,
  setFieldValue,
  isEdit,
  setThresholds
}) => {
  const [activeButtons, setActiveButtons] = useState([]);

  useEffect(() => {
    if (isEdit) {
      const newActiveButtons = thresholds.map((threshold) =>
        threshold.thresholdType === "Percentage" ? 1 : 0
      );
      setActiveButtons(newActiveButtons);
    } else {
      setActiveButtons(thresholds.map((threshold) =>
        threshold.thresholdType === "Percentage" ? 1 : 0
      ));
    }
  }, [thresholds, isEdit]);

  const handleAddThreshold = () => {
    addThreshold(thresholds, setThresholds, activeButtons, setActiveButtons);
  };

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardHeader className="d-flex justify-content-between align-items-center mb-0">
            <h5>Threshold Notification</h5>
            <button type="button" className="btn btn-primary" onClick={handleAddThreshold}>
              + Add
            </button>
          </CardHeader>
          <CardBody>
          <div className="d-flex justify-content-between align-items-center">
            <p className="mb-2">Configure absolute or percentage threshold notification values.</p>
          </div>
            {thresholds.map((threshold, index) => (
              <Row key={index} className="mb-3">
                <Col lg={1} md={3} sm={3}>
                  <div className="btn-group" role="group">
                    <button
                      type="button"
                      className={`btn ${activeButtons[index] === 0 ? "btn-primary" : "btn-outline-secondary"}`}
                      onClick={() =>
                        toggleButton(index, 0, activeButtons, setActiveButtons, thresholds, setThresholds)
                      }
                    >
                      123
                    </button>
                    <button
                      type="button"
                      className={`btn ${activeButtons[index] === 1 ? "btn-primary" : "btn-outline-secondary"}`}
                      onClick={() =>
                        toggleButton(index, 1, activeButtons, setActiveButtons, thresholds, setThresholds)
                      }
                    >
                      %
                    </button>
                  </div>
                </Col>
                <Col lg={3} md={6} sm={6}>
                  <InputField
                    type="number"
                    name={`thresholds[${index}].thresholdValue`}
                    className={
                      touched.thresholds?.[index]?.thresholdValue && errors.thresholds?.[index]?.thresholdValue
                        ? "is-invalid"
                        : ""
                    }
                    value={threshold.thresholdValue}
                    placeholder={`Enter ${threshold.thresholdType === "Fixed" ? "threshold value" : "percentage"}`}
                    handleChange={(e) =>
                      handleThresholdChange(index, "thresholdValue", e.target.value)
                    }
                    invalid={
                      touched.thresholds?.[index]?.thresholdValue && errors.thresholds?.[index]?.thresholdValue
                    }
                    required
                  />
                  {errors.thresholds?.[index]?.thresholdValue &&
                    touched.thresholds?.[index]?.thresholdValue && (
                      <p className="text-danger">{errors.thresholds[index].thresholdValue}</p>
                  )}
                </Col>
                <Col lg={2} md={3} sm={3} className="mt-1">
                  <Link
                    to="#"
                    className="link-danger fs-4"
                    onClick={() =>
                      removeThreshold(index, setFieldValue, values)
                    }
                  >
                    <i data-testid="delete" className="ri-delete-bin-5-line"></i>
                  </Link>
                </Col>
              </Row>
            ))}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ThresholdNotification;
