import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Col, Input, Row } from "reactstrap"
import { LabelText } from "../Utils/Input"
import { handleCheckboxChange, handleServiceClick } from "./Constants/ModifyAddOfferFunctions"
import { useState } from "react"

export const AddOfferForPolicyAccordion = ({ setSelectedOnes, open, toggle, loading, filteredResults, setExpandedServices, selectedOffers, setSelectedOffers }) => {
    const [viewDetails, setViewDetails] = useState({})

    const toggleViewDetails = (serviceIndex, offerIndex) => {
        const key = `${serviceIndex}-${offerIndex}`;
        setViewDetails((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    return (
        <Accordion open={open} toggle={toggle}>
            {loading ? (
                <p></p>
            ) : (
                <>
                    {filteredResults.map(([serviceName, offers], index) => (
                        serviceName && (
                            <AccordionItem key={index}>
                                <AccordionHeader targetId={`${index}`} onClick={() => handleServiceClick(serviceName, setExpandedServices)}>
                                    {serviceName}
                                </AccordionHeader>
                                <AccordionBody accordionId={`${index}`}>
                                    {offers.map((offer,offerIndex) => (
                                        serviceName && (
                                            <Row className="gy-4 mb-2 ms-2" key={offer.offerCode}>
                                                <Col xxl={1} md={6}>
                                                    <div>
                                                        <div className='m-4'>
                                                            <Input type="checkbox" className=' border-black fs-5' checked={selectedOffers.some(o => o.offerCode === offer.offerCode)} onChange={() => handleCheckboxChange(offer, selectedOffers, setSelectedOffers, setSelectedOnes)} style={{ borderWidth: '2px', borderColor: '#000', borderRadius: '0' }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className="p-3" xxl={4} md={6}>
                                                    <div>
                                                        <div className="d-flex mb-3">
                                                            <h5 className="offer-name offer-code-color">Offer Code : <span className="text-black">{offer.offerCode}</span></h5>
                                                        </div>
                                                        <div className="d-flex">
                                                            {offer.Grants?.length > 0 && (
                                                                <div className="badge-for-order me-2 p-1">
                                                                    <LabelText classLabelName="mb-0 me-1 ps-1 badge-for-offer-count" displayText={offer.Grants?.length} />
                                                                    <LabelText classLabelName="mb-0" displayText="Grant" />
                                                                </div>
                                                            )}
                                                            {offer.Charges?.length > 0 && (
                                                                <div className="badge-for-order me-2 p-1">
                                                                    <LabelText classLabelName="mb-0 me-1 ps-1 badge-for-offer-count" displayText={offer.Charges?.length} />
                                                                    <LabelText classLabelName="mb-0" displayText="Charge" />
                                                                </div>
                                                            )}
                                                            {offer.Usage?.length > 0 && (
                                                                <div className="badge-for-order me-2 p-1">
                                                                    <LabelText classLabelName="mb-0 me-1 ps-1 badge-for-offer-count" displayText={offer.Usage?.length} />
                                                                    <LabelText classLabelName="mb-0" displayText="Usage" />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xxl={1} md={6}></Col>
                                                <Col xxl={3} md={6}>
                                                    <div className="d-flex justify-content-start align-items-center mb-3">
                                                        <h5 className="fs-6 pe-2 mt-3 fw-semibold offer-code-color">
                                                            Term Validity:
                                                        </h5>
                                                        <h5 className="fs-6 mt-3 fw-lighter text-black">{offer.termValidity}</h5>
                                                    </div>
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        {offer?.categories?.map((category) => (
                                                            <div className="me-2 text-black border px-2 py-1 offer-bg-color ">{category}</div>
                                                        ))}
                                                    </div>
                                                </Col>
                                                <Col xxl={3} md={6}>
                                                    <div className="d-flex justify-content-end align-items-center mt-4 fst-italic view-more-color" onClick={() => { toggleViewDetails(index, offerIndex) }}>View {viewDetails[`${index}-${offerIndex}`] ? "Less" : "More"}</div>
                                                </Col>
                                                {viewDetails[`${index}-${offerIndex}`] &&
                                                                <Row className="ms-1 mt-3">
                                                                    <Col xxl={1} md={6}></Col>
                                                                    <Col className="px-1" xxl={4} md={6}>
                                                                        <div className="d-flex mb-3">
                                                                            <h5 className="offer-name offer-code-color">Grant</h5>
                                                                        </div>
                                                                        <div className="d-flex justify-content-start align-items-center">
                                                                            {offer?.GrantCard?.length ? offer?.GrantCard?.map((grant) => (
                                                                                <div className="me-2 text-black border px-2 py-1 offer-bg-color ">
                                                                                    <div className="fs-3 fw-bold d-flex justify-content-center align-items-center">
                                                                                        {grant?.grantAmount} {grant?.uom}
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                                        {grant?.name}
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                                        {"("}{grant?.oneTimeorRecurring}{")"}
                                                                                    </div>
                                                                                </div>
                                                                            )) : <div className="text-black">No grants available</div>
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                    <Col xxl={1} md={6}></Col>
                                                                    <Col xxl={3} md={6}>
                                                                        <div className="d-flex mb-3">
                                                                            <h5 className="offer-name offer-code-color">Charge</h5>
                                                                        </div>
                                                                        <div className="d-flex justify-content-start align-items-center">
                                                                            {offer?.ChargeCard?.length ? offer?.ChargeCard?.map((charge) => (
                                                                                <div className="me-2 text-black border py-3 px-2 offer-bg-color ">
                                                                                    <div className="fs-3 fw-bold d-flex justify-content-center align-items-center">
                                                                                        {charge?.currencySymbol} {charge?.chargeAmount}
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                                        {"("}{charge?.oneTimeorRecurring}{")"}
                                                                                    </div>
                                                                                </div>
                                                                            )):<div className="text-black">No charges available</div>}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                <hr></hr>
                                            </Row>
                                        )
                                    ))}
                                </AccordionBody>
                            </AccordionItem>
                        )
                    ))}
                </>
            )}
        </Accordion>
    )
}