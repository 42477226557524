import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    addEnquiry : [],
    loading : false,
    error : null,
};

const addEnquirySlice = createSlice({
    name:"addEnquiry",
    initialState,
    reducers: {
        fetchAddEnquirySuccess: (state,action) => {
            state.loading = false;
            state.addDevice = action.payload;
        },
        fetchAddEnquiryFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    },
})

export const {
    fetchAddEnquirySuccess,
    fetchAddEnquiryFailure
} = addEnquirySlice.actions

export default addEnquirySlice.reducer

