import React, { useState, useEffect, useRef } from 'react';
const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(1); // Start from 1 for seamless looping
  const [cardWidth, setCardWidth] = useState(0);
  const wrapperRef = useRef(null);
  const dotsRef = useRef([]);
  const cardContent = [
    {
      title: "Boost Operational Efficiency",
      description: "Automate the usage rating processes and minimize operational overhead, enabling your teams to focus on innovation."
    },
    {
      title: "Ensure Revenue Assurance",
      description: "Achieve precision billing and eliminate revenue leakages, even for the most complex usage scenarios."
    },
    {
      title: "Unlock Business Agility",
      description: "Adapt to dynamic market demands with ease. Rapidly design new plans, onboard partners, and scale your operations to meet evolving challenges."
    },
    {
      title: "Enhance Customer Satisfaction",
      description: "Deliver transparency and flexibility with real-time updates, personalized rate plans, and seamless billing experiences."
    },
    {
      title: "Future-Proof Monetization",
      description: "Prepare your business for tomorrow with a platform designed for flexibility and growth, ensuring long-term success in any industry."
    }
  ];

  const cardCount = cardContent.length;
  const fullContent = [
    cardContent[cardCount - 2], // Second-to-last card for visual continuity
    cardContent[cardCount - 1], // Last card
    ...cardContent,
    cardContent[0], // First card
    cardContent[1] // Second card for visual continuity
  ];

  const updateCardWidth = () => {
    if (wrapperRef.current) {
      const cards = wrapperRef.current.querySelectorAll('.carousel-card');
      setCardWidth(cards[0].offsetWidth + 20);
    }
  };

  const updateCarousel = (index, smooth = true) => {
    if (wrapperRef.current) {
      wrapperRef.current.style.transition = smooth ? 'transform 0.5s ease-in-out' : 'none';
      wrapperRef.current.style.transform = `translateX(-${index * cardWidth}px)`;
    }
    const actualIndex = (index - 2 + cardCount) % cardCount;
    dotsRef.current.forEach((dot, i) => {
      dot.classList.toggle('active', i === actualIndex);
    });
  };

  const handleTransitionEnd = () => {
    if (currentIndex === 0) {
      setCurrentIndex(cardCount);
      updateCarousel(cardCount, false);
    } else if (currentIndex === cardCount + 2) {
      setCurrentIndex(2);
      updateCarousel(2, false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prev) => prev + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    updateCardWidth();
    window.addEventListener('resize', updateCardWidth);
    return () => {
      window.removeEventListener('resize', updateCardWidth);
    };
  }, []);

  useEffect(() => {
    updateCarousel(currentIndex);
  }, [currentIndex]);

  const handleDotClick = (index) => {
    setCurrentIndex(index + 2); 
  };

  let touchStartX = 0;
  let touchEndX = 0;

  const handleTouchStart = (e) => {
    touchStartX = e.changedTouches[0].screenX;
  };

  const handleTouchEnd = (e) => {
    touchEndX = e.changedTouches[0].screenX;
    if (touchStartX - touchEndX > 50) {
      setCurrentIndex((prev) => prev + 1);
    } else if (touchEndX - touchStartX > 50) {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  return (
    <div className="carousel-container">
      <div
        className="carousel-wrapper"
        ref={wrapperRef}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTransitionEnd={handleTransitionEnd}
      >
        {fullContent.map((card, i) => (
          <div key={i} className="carousel-card">
            <h3 className="text-white">{card.title}</h3>
            <p>{card.description}</p>
          </div>
        ))}
      </div>
      <div className="indicators">
        {Array.from({ length: cardCount }).map((_, i) => (
          <button
            key={i}
            className={`dot ${i === (currentIndex - 2 + cardCount) % cardCount ? 'active' : ''}`}
            onClick={() => handleDotClick(i)}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
