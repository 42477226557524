import { toast } from "react-toastify"
import { TERMINATE_SESSION } from "../../constants/constants"
import { fetchDetailedBalance } from "../../customer/detailedBalance/thunk"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"
import { setDeleteSuccessMessage } from "../../toastMessage/action"
import { fetchAllSessions } from "../getAllSessions/thunk"
import { fetchTerminateSessionFailure, fetchTerminateSessionSuccess } from "./reducer"
import { fetchCustomerSummary } from "../../customer/simulate/thunk"

export const terminateSession = (jsonObj, setLoading,customerId,subscriptionId,setUpdateSession,setButtonInitiateSession) => async (dispatch) => {
    await determineBaseUrlAndPerformApiCall(`${TERMINATE_SESSION}`, 'POST',jsonObj).then((response) => {
        setLoading(true)
        if (response?.error) {
            setLoading(false)
            dispatch(fetchTerminateSessionFailure(response.error.message))
            toast.error(`${response.error.message}`)
        }
        if (!response?.error) {
            setLoading(false)
            setUpdateSession(false)
            setButtonInitiateSession(true)
            dispatch(fetchAllSessions(customerId,subscriptionId))
            dispatch(fetchDetailedBalance(subscriptionId, setLoading,false));
            dispatch(fetchCustomerSummary(customerId, setLoading, "View"));
            dispatch(fetchTerminateSessionSuccess(response))
            toast.success('Session Terminated Successfully')
            
        }
    })
}