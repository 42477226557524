import React, { useEffect, useRef, useState } from 'react';
import { Card, CardBody, Container, Nav, NavItem, NavLink, Row, Col, Button } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { cancelOffer } from './constants/GrantsAndRecurringFunctions';
import { convertOfferFieldsForExporting } from './constants/exportOfferData';
import { MaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllOffers } from '../../../slices/offer/thunk';
import OfferPreview from './OfferPreview';
import { ToastContainer, toast } from 'react-toastify';
import { handleExportData } from '../../Utils/ExportToCsv';
import { chargesColumnData, grantsColumnData, offerColumns, usageRatePlanColumnData } from './constants/ColumnsForOfferTable';
import { extractBaseAndAddOnOfferDates, formatBaseAndAddOnOfferColumns, termValidity } from './constants/checkEmptyFields';
import { tabChange } from '../../Utils/SetDefaultRowData';
import { Loader } from '../../Utils/Loader';
import { setEditSuccessMessage, setSuccessMessage, setDocumentSuccessMessage } from '../../../slices/toastMessage/action';
import { navigateToAddOffer } from './constants/GrantsAndRecurringFunctions';
import { OfferRowOptions } from './OfferRowOptions';
import CommonModal from '../../Utils/CommonModal';
import { PAGE_TITLES } from '../../Common/constants';
import classnames from 'classnames';
import { useColumnSettings } from '../../Utils/useColumnSettings';
import { getView } from '../../../slices/View/thunk';
import { getAllService } from '../../../slices/services/thunk';
import { bulkDelete } from '../../../slices/BulkDelete/thunk';
import { MenuItem } from '@mui/material';
import { storeRow, setDefault } from '../../Utils/SetDefaultRowData';
import SelectField from '../../Utils/SelectField';
import { categoryOptions, handleCategoryChange } from './constants/UpdateOfferJsonObj';

function OffersListings() {
    const [loading, setLoading] = useState(false);
    const [messageForPopUp, setMessageForPopUp] = useState('');
    const [activeTab, setActiveTab] = useState('0.1');
    const [activeTabForAddOn, setActiveTabForAddOn] = useState('1');
    const [index, setIndex] = useState(null);
    const [offerArray, setOfferArray] = useState([]);
    const [modalForBulkDelete, setModalForBulkDelete] = useState(false)
    const [selectedRowsForBulkDelete, setSelectedRowsForBulkDelete] = useState()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [offerId, setOfferId] = useState('');
    const [selectAllBaseOffer, setSelectAllBaseOffer] = useState(false)
    const [selectedBaseOffer, setSelectedBaseOffer] = useState([])
    const checkboxBaseRef = useRef(null);
    const [selectAllAddOnOffer, setSelectAllAddOnOffer] = useState(false)
    const [selectedAddOnOffer, setSelectedAddOnOffer] = useState([])
    const [selectedBaseCategory, setSelectedBaseCategory] = useState()
    const [selectedAddOnCategory, setSelectedAddOnCategory] = useState()
    const [baseCategoryError, setBaseCategoryError] = useState(false)
    const [addOnCategoryError, setAddOnCategoryError] = useState(false)
    const checkboxAddOnRef = useRef(null);
    const isMobile = window.innerWidth <= 768;

    const toggleModal = (id, singleOrMulti) => {
        if (singleOrMulti === "single") {
            setOfferId(id);
            setModal(!modal);
        }
        else {
            setSelectedRowsForBulkDelete(id)
            setModalForBulkDelete(!modalForBulkDelete)
        }
        document.body.style.overflow = 'auto'
    };
    useEffect(() => {
        dispatch(fetchAllOffers(setLoading));
        dispatch(getAllService(setLoading))
        dispatch(getView(setLoading))
    }, [dispatch]);

    const rolename = localStorage.getItem('roleName');
    const offers = useSelector((state) => state.Offer.offer);
    const permissions = useSelector((state) => state.permissions.permissions);
    const offerPermission = permissions.find((resource) => resource.resourceName === 'offer');
    const showSuccessMessage = useSelector((state) => state.SuccessToast.showSuccessMessage);
    const showEditSuccessMessage = useSelector((state) => state.SuccessToast.showEditSuccessMessage);
    const showDocumentSuccessMessage = useSelector((state) => state.SuccessToast.showDocumentSuccessMessage);

    let baseOffers = offers.filter(offer => !offer.addOnOffer);
    let addOnOffers = offers.filter(offer => offer.addOnOffer);

    const [filteredBaseOffers, setFilteredBaseOffers] = useState(baseOffers);
    const [filteredAddOnOffers, setFilteredAddOnOffer] = useState(addOnOffers)

    useEffect(() => {
        setFilteredBaseOffers(offers.filter(offer => !offer.addOnOffer))
        setFilteredAddOnOffer(offers.filter(offer => offer.addOnOffer))
    }, [offers])

    const baseOfferDates = extractBaseAndAddOnOfferDates(baseOffers);
    const addOnOfferDates = extractBaseAndAddOnOfferDates(addOnOffers);

    const baseEndDates = baseOfferDates.endDatesArray;
    const baseStartDates = baseOfferDates.startDatesArray;

    const addOnEndDates = addOnOfferDates.endDatesArray;
    const addOnStartDates = addOnOfferDates.startDatesArray;

    const baseOfferColumnsData = formatBaseAndAddOnOfferColumns(baseOffers);
    const addOnOfferColumnsData = formatBaseAndAddOnOfferColumns(addOnOffers);

    const { globalScope: baseGlobalScope, grant: baseGrant, charge: baseCharge, usage: baseUsage } = baseOfferColumnsData;
    const { globalScope: addOnGlobalScope, grant: addOnGrant, charge: addOnCharge, usage: addOnUsage } = addOnOfferColumnsData;

    useEffect(() => {
        if (checkboxBaseRef.current) {
            checkboxBaseRef.current.indeterminate = selectedBaseOffer.length > 0 && selectedBaseOffer.length < baseOffers.length;
        }
        setSelectAllBaseOffer(selectedBaseOffer?.length > 0 && baseOffers?.length > 0 && selectedBaseOffer?.length === baseOffers?.length);
        if (checkboxAddOnRef.current) {
            checkboxAddOnRef.current.indeterminate = selectedAddOnOffer.length > 0 && selectedAddOnOffer.length < addOnOffers.length;
        }
        setSelectAllAddOnOffer(selectedAddOnOffer?.length > 0 && addOnOffers?.length > 0 && selectedAddOnOffer?.length === addOnOffers?.length);
    }, [selectedBaseOffer, baseOffers.length, setSelectAllBaseOffer, selectedAddOnOffer, addOnOffers.length, setSelectAllAddOnOffer]);

    const columns = offerColumns(baseGrant, baseCharge, baseUsage, baseGlobalScope, baseStartDates, baseEndDates, rolename, selectAllBaseOffer, setSelectAllBaseOffer, baseOffers, setSelectedBaseOffer, selectedBaseOffer, checkboxBaseRef, isMobile);
    const addOnColumns = offerColumns(addOnGrant, addOnCharge, addOnUsage, addOnGlobalScope, addOnStartDates, addOnEndDates, rolename, selectAllAddOnOffer, setSelectAllAddOnOffer, addOnOffers, setSelectedAddOnOffer, selectedAddOnOffer, checkboxAddOnRef, isMobile);

    useEffect(() => {
        if (showSuccessMessage) {
            toast.success('Offer Created Successfully');
            dispatch(setSuccessMessage(false));
        }
        if (showEditSuccessMessage) {
            toast.success('Offer Updated Successfully');
            dispatch(setEditSuccessMessage(false));
        }
        if (showDocumentSuccessMessage) {
            toast.success('Import Successful');
            dispatch(setDocumentSuccessMessage(false));
        }
    }, [showSuccessMessage, showEditSuccessMessage, showDocumentSuccessMessage, dispatch]);

    const grantsColumn = grantsColumnData();
    const chargesColumn = chargesColumnData();
    const usageRatePlanColumn = usageRatePlanColumnData();
    const exportData = convertOfferFieldsForExporting(offers);

    const {
        settingsModalOpen: settingsModal1,
        viewColumns: viewColumns1,
        toggleSettingsModal: toggleSettingsModal1,
        SettingsSidebar: SettingsModal1
    } = useColumnSettings('baseOffer', columns);

    const {
        settingsModalOpen: settingsModal2,
        viewColumns: viewColumns2,
        toggleSettingsModal: toggleSettingsModal2,
        SettingsSidebar: SettingsModal2
    } = useColumnSettings('addOnOffer', addOnColumns);

    const containsNull1 = viewColumns1.some(value => value === undefined);
    const containsNull2 = viewColumns2.some(value => value === undefined);
    const result1 = !containsNull1;
    const result2 = !containsNull2;

    const baseCategories = categoryOptions(baseOffers)
    const addOnCategories = categoryOptions(addOnOffers)

    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title={PAGE_TITLES.OFFER_LIST} pageTitle={PAGE_TITLES.PRICING} />
                    <ToastContainer position='top-center' />
                    <Row>
                        <Col lg={((settingsModal1 && activeTabForAddOn === "1") || (settingsModal2 && activeTabForAddOn === "2")) ? 10 : 12}>
                            <div className="listingjs-table " id="offerList">
                                <Card>
                                    <CardBody>
                                        <div className=' border-bottom'>
                                            <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0 " role="tablist">
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTabForAddOn === "1" })}
                                                        onClick={() => {
                                                            tabChange("1", activeTabForAddOn, setActiveTabForAddOn)
                                                            if(!selectedAddOnCategory?.length) {
                                                                setSelectedAddOnCategory([])
                                                            }
                                                        }}>
                                                        <i className="fas fa-home"></i>
                                                        Base
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTabForAddOn === "2" })}
                                                        onClick={() => {tabChange("2", activeTabForAddOn, setActiveTabForAddOn)
                                                        if(!selectedBaseCategory?.length) {
                                                            setSelectedBaseCategory([])
                                                        }
                                                        }}>
                                                        <i className="far fa-user"></i>
                                                        Add-On
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                        <Row className="mb-3 mt-1 table-responsive">
                                            {loading && (<Loader />)}
                                            {(offers && activeTabForAddOn) &&
                                                <MaterialReactTable
                                                    columns={activeTabForAddOn === "1" ? result1 ? columns.filter((column) => column.accessorKey ? viewColumns1?.includes(column.accessorKey) : viewColumns1?.includes(column.header)) : columns.filter((column) => viewColumns1?.includes(column.accessorKey)) : result2 ? addOnColumns.filter((column) => column.accessorKey ? viewColumns2?.includes(column.accessorKey) : viewColumns2?.includes(column.header)) : addOnColumns.filter((column) => viewColumns2?.includes(column.accessorKey))}
                                                    data={activeTabForAddOn === "1" ? filteredBaseOffers : filteredAddOnOffers}
                                                    icons={{
                                                        DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                                    }}
                                                    muiTableDetailPanelProps={{
                                                        sx: {
                                                            fontFamily: "Inter,sans-serif"
                                                        }
                                                    }}
                                                    enableDensityToggle={false}
                                                    positionToolbarAlertBanner='none'
                                                    muiSelectCheckboxProps={{
                                                        color: 'warning'
                                                    }}
                                                    muiSelectAllCheckboxProps={{
                                                        color: 'warning'
                                                    }}
                                                    renderTopToolbarCustomActions={({ table }) => (
                                                        <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between w-100">
                                                            <div className="d-flex flex-column flex-md-row">
                                                                <Button color='secondary' className='btn-label me-2 mb-2 mb-md-0' onClick={() => navigate('/import-data')} ><i className='ri-download-line label-icon align-middle fs-16 me-2 ' ></i>Import Data</Button>
                                                                <Button color="secondary" className="btn-label me-2 mb-2 mb-md-0" onClick={() => { handleExportData(exportData) }}> <i className="ri-upload-line label-icon align-middle fs-16 me-2 "></i> Export Data</Button>
                                                                {offerPermission && offerPermission.permissionList.includes('create') ?
                                                                    <Button color="secondary" className="btn-label me-2 mb-2 mb-md-0" onClick={() => navigateToAddOffer(navigate)}><i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Offer</Button> : null}
                                                                {(activeTabForAddOn === "1" ? selectedBaseOffer?.length : selectedAddOnOffer?.length) ? <Button color='main-color' className='btn me-2' onClick={() => {
                                                                    const selectedRows = activeTabForAddOn === "1" ? selectedBaseOffer : selectedAddOnOffer
                                                                    const names = selectedRows.map(row => row?.offerCode);
                                                                    let uniqueOffers = Array.from(new Set(names))
                                                                    setOfferArray(uniqueOffers);
                                                                    setMessageForPopUp(`Are you sure you want to delete the selected items?`)
                                                                    toggleModal(selectedRows, "multi")
                                                                }}>Delete</Button> : null}
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <div>
                                                                    <SelectField
                                                                        isMulti={true}
                                                                        className={`category-offer-width`}
                                                                        placeholder={"Select Category"}
                                                                        value={activeTabForAddOn === "1" ? selectedBaseCategory : selectedAddOnCategory}
                                                                        options={
                                                                            activeTabForAddOn === "1"
                                                                              ? baseCategories
                                                                              : addOnCategories
                                                                          }
                                                                        handleChange={(value) => {
                                                                            if (activeTabForAddOn === "1") {
                                                                                if (value?.length <= 5) {
                                                                                    handleCategoryChange(value, setFilteredBaseOffers, baseOffers, true, setSelectedBaseCategory, setSelectedAddOnCategory);
                                                                                    setBaseCategoryError(false);
                                                                                } else {
                                                                                    setBaseCategoryError(true);
                                                                                }
                                                                            } else {
                                                                                if (value?.length <= 5) {
                                                                                    handleCategoryChange(value, setFilteredAddOnOffer, addOnOffers, false, setSelectedBaseCategory, setSelectedAddOnCategory);
                                                                                    setAddOnCategoryError(false);
                                                                                } else {
                                                                                    setAddOnCategoryError(true);
                                                                                }
                                                                            }
                                                                        }}
                                                                        menuPortalTarget={document.querySelector("body")}
                                                                    />
                                                                    {activeTabForAddOn === "1" && baseCategoryError && (
                                                                        <div className="text-danger mt-1 fs-6">You can select only up to 5 options for Base Categories</div>
                                                                    )}
                                                                    {activeTabForAddOn !== "1" && addOnCategoryError && (
                                                                        <div className="text-danger mt-1 fs-6">You can select only up to 5 options for Add-On Categories</div>
                                                                    )}

                                                                </div>
                                                                <div color="secondary" className="btn-icon me-2 cursor-img" onClick={activeTabForAddOn === "1" ? toggleSettingsModal1 : toggleSettingsModal2}>
                                                                    <i className="ri-settings-2-line fs-4"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    enableColumnOrdering={true}
                                                    enableColumnDragging={true}
                                                    enableRowActions={true}
                                                    displayColumnDefOptions={{
                                                        'mrt-row-actions': {
                                                            header: '',
                                                            enableHiding: true,
                                                            size: 45,
                                                            grow: true
                                                        },
                                                    }}
                                                    initialState={{
                                                        columnOrder: [
                                                            'checkbox',
                                                            'mrt-row-actions',
                                                            'offerName',
                                                            'offerCode',
                                                            'ratePlan',
                                                            'globalScope',
                                                            'offerStartsOn',
                                                            'offerEndsOn'
                                                        ],
                                                        columnVisibility: {
                                                            'mrt-row-expand': false,
                                                        }

                                                    }}
                                                    defaultColumn={{
                                                        minSize: 1,
                                                        maxSize: 9001,
                                                        muiTableBodyCellProps: {
                                                            sx: {
                                                                fontFamily: "Inter,sans-serif"
                                                            }
                                                        },
                                                        muiTableHeadCellProps: {
                                                            sx: {
                                                                fontFamily: "Inter,sans-serif"
                                                            }
                                                        }
                                                    }}
                                                    layoutMode='grid'
                                                    renderRowActionMenuItems={({ closeMenu, row }) => [
                                                        offerPermission && offerPermission.permissionList.includes('view')
                                                            ? <MenuItem className='row-options' data-testid="view" key="view" onClick={() => { storeRow(row, setIndex, index), row.toggleExpanded(), setDefault(row.index, setActiveTab), closeMenu() }}>
                                                                <i className='ri  ri-eye-line me-2' /> View </MenuItem> : null,
                                                        offerPermission && offerPermission.permissionList.includes('update') && row.original.globalScope !== true && row?.original?.validity?.to !== "0001-01-01T00:00:00Z"
                                                            ? <MenuItem className='row-options' data-testid="update" key="update" onClick={() => { navigate(`/update-offer/${row.original.offerCode}`) }} >
                                                                <i className='bx bxs-edit me-2' /> Edit </MenuItem> : null,
                                                        offerPermission && offerPermission.permissionList.includes('update')
                                                            ? <MenuItem className='row-options' data-testid="clone" key="clone" onClick={() => { navigate(`/clone-offer/${row.original.offerCode}`) }} >
                                                                <i className='ri ri-file-copy-2-line me-2' /> Clone </MenuItem> : null,
                                                        offerPermission && offerPermission.permissionList.includes('delete')
                                                            ? <MenuItem className='row-options' key="delete" onClick={() => { setMessageForPopUp(`Are you sure you want to Delete this Offer "${row.original.offerCode}"?`), toggleModal(row, "single"), closeMenu() }}  >
                                                                <i className='ri  ri-delete-bin-5-line me-2' /> Delete </MenuItem> : null,
                                                    ]}
                                                    renderDetailPanel={({ row }) => (
                                                        <OfferPreview row={row} tabChange={tabChange} setActiveTab={setActiveTab} termValidity={termValidity} activeTab={activeTab} grantsColumn={grantsColumn} chargesColumn={chargesColumn} usageRatePlanColumn={usageRatePlanColumn} />
                                                    )}
                                                />}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                        {activeTabForAddOn === "1" ? SettingsModal1() : SettingsModal2()}
                    </Row>
                </Container>
            </div>
            <div>
                <CommonModal open={modalForBulkDelete ? modalForBulkDelete : modal} toggle={() => { if (modal) { toggleModal(offerId, "single") } else { toggleModal(selectedRowsForBulkDelete, "multi") } }} buttonText={"Yes, Delete It"} messageForPopUp={messageForPopUp} modalAction={() => {
                    if (modal) {
                        cancelOffer(dispatch, offerId.original.offerCode, setLoading);
                        toggleModal(offerId, "single");
                    }
                    else {
                        let jsonObj = { ids: offerArray, type: "Offer" }
                        dispatch(bulkDelete(jsonObj, setLoading))
                        toggleModal(selectedRowsForBulkDelete, "multi")
                    }
                }} />
            </div>
        </React.Fragment>
    );
}

export default OffersListings;