import { GET_ALL_SESSIONS } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchSessionFailure, fetchSessionSuccess } from "./reducer";

export const fetchAllSessions = (customerId, subscriptionId) => async (dispatch) => {
    try {
        const response = await determineBaseUrlAndPerformApiCall(
            `${GET_ALL_SESSIONS}/${customerId}${subscriptionId ? `?subscriptionId=${subscriptionId}` : ''}`,
            'GET'
        );
        if(response == null){
            dispatch(fetchSessionFailure("Their is no session data"));
            dispatch(fetchSessionSuccess([]))
        }
        else if (response?.error) {
            dispatch(fetchSessionFailure(response.error.message));
        } else if (response) {
            const reversedData = Array.isArray(response) ? response.reverse() : [];
            dispatch(fetchSessionSuccess(reversedData));
        } else {
            dispatch(fetchSessionFailure("No data received from the server"));
        }
    } catch (error) {
        dispatch(fetchSessionFailure(error.message || "An unexpected error occurred"));
    }
};
