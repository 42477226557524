import { Card, CardBody, CardHeader, Col, Row, FormFeedback, FormGroup, Input } from "reactstrap";
import InputField from "../../Utils/InputField";
import SelectField from "../../Utils/SelectField";
import { LabelText } from "../../Utils/Input";
import { SubscriptionEndsOnOptions, SubscriptionStartsOnOptions, timePeriodOption, timePeriodOptionStart } from "./constants/initialValues";
import { setEndsOnOption } from "./constants/displayInfinite";
import { handleEndDate, handleStartDate } from "./constants/initialValues";
import { ErrorMessage } from "formik";
import { useEffect } from "react";
import CommonDateTimePicker from "../../Utils/CommonDateTimePicker";
import moment from "moment";
import { CreatableSelectField } from "../../Utils/CreatableSelectField";


export default function OfferDetails({
    touched, setFormValues, isEdit, isSubscribed,
    errors, handleChange, handleBlur, values, setFieldTouched, setFieldValue, state, setState, TimePeriodOptions, startDate, setStartDate, handleStartDateChange, globalState, setGlobalState, addOnState, setAddOnState
}) {
    return (
        <Card>
            <CardHeader>
                <h4 className="card-title mb-0 flex-grow-1">Offer</h4>
            </CardHeader>
            <CardBody>
                <Row className="mb-4 gy-4">
                    <Col xxl={4} md={6}>
                        <LabelText htmlFor={"offerName"} className={"text-danger"} displayText={"Name"} important={"*"} classLabelName={isEdit ? "label-disable-color-edit-offer" : ""} />
                        <InputField name={"offerName"} disabled={isEdit ? true : false} placeholder={"Enter offer name"} type={"text"} className={`form-control ${isEdit ? "label-disable-color-edit-offer" : ""}`} id={"offerName"} handleChange={handleChange} handleBlur={handleBlur} value={values.offerName}
                            invalid={touched.offerName && errors.offerName
                                ? true
                                : false} />
                        {(touched.offerName &&
                            errors.offerName) && (
                                <FormFeedback type="invalid">
                                    {errors.offerName}
                                </FormFeedback>
                            )}
                    </Col>
                    <Col xxl={4} md={6}>
                        <LabelText htmlFor={"offerCode"} className={"text-danger"} displayText={"Code"} important={"*"} classLabelName={isEdit ? "label-disable-color-edit-offer" : ""} />
                        <InputField name={"offerCode"} disabled={isEdit ? true : false} placeholder={"Enter offer code"} type={"text"} className={`form-control ${isEdit ? "label-disable-color-edit-offer" : ""}`} id={"offerCode"} handleChange={handleChange} handleBlur={handleBlur} value={values.offerCode}
                            invalid={touched.offerCode && errors.offerCode
                                ? true
                                : false} />
                        {(touched.offerCode &&
                            errors.offerCode) && (
                                <FormFeedback type="invalid">
                                    {errors.offerCode}
                                </FormFeedback>
                            )}
                    </Col>
                    {!isEdit ? <Col className="mt-4" xxl={4} md={6}>
                        <LabelText htmlFor={"addon"} className={"text-danger"} displayText={"Add On"} check />
                        <FormGroup switch >
                            <div className="flex-column justify-content-center align-items-center">
                                <Input
                                    type={"switch"}
                                    checked={addOnState}
                                    onClick={() => setAddOnState(!addOnState)}
                                />
                                <p>To enable add on offers.</p>
                            </div>
                        </FormGroup>
                    </Col> : <Col xxl={4} md={6}></Col>}

                    <Col xxl={4} md={6}>
                        <LabelText htmlFor={"offerStartsOn"} className={"text-danger"} displayText={"Starts on"} important={"*"} />
                        <CommonDateTimePicker
                            className={`form-control ${touched.offerStartsOn &&
                                !values.offerStartsOn ? (
                                "error-input"
                            ) : ""}`}
                            views={['year', 'month', 'day']}
                            value={values.offerStartsOn}
                            name={`offerStartsOn`}
                            format="DD/MM/YYYY"
                            minDate={moment()}
                            handleChange={(value) => {
                                setFieldValue('offerStartsOn', value)
                                //future use
                                // handleStartDate(handleStartDateChange, setStartDate, setFieldTouched, setFieldValue)
                            }}
                        />
                        {errors.offerStartsOn &&
                            touched.offerStartsOn && (
                                <p className="text-danger error">
                                    {" "}
                                    {errors.offerStartsOn}{" "}
                                </p>
                            )}
                    </Col>
                    <Col xxl={4} md={6}>
                        {!state ?
                            <div>
                                <LabelText htmlFor={"offerEndsOn"} className={"text-danger"} displayText={"Ends on"} important={"*"} />
                                <CommonDateTimePicker
                                    className={`form-control ${touched.offerEndsOn &&
                                        !values.offerEndsOn ? (
                                        "error-input"
                                    ) : ""}`}
                                    value={values.offerEndsOn}
                                    name={`offerEndsOn`}
                                    format="DD/MM/YYYY"
                                    views={['year', 'month', 'day']}
                                    minDate={values.offerStartsOn}
                                    handleChange={handleEndDate(setFieldTouched, setFieldValue)}
                                />
                                {errors.offerEndsOn &&
                                    touched.offerEndsOn && (
                                        <p className="text-danger error">
                                            {" "}
                                            {errors.offerEndsOn}{" "}
                                        </p>
                                    )}
                            </div>
                            : null}
                    </Col>
                    {!isEdit && !addOnState ? <Col className="mt-4" xxl={4} md={6}>
                        <LabelText htmlFor={"globalOffer"} className={"text-danger"} displayText={"Global Offer"} check />
                        <FormGroup switch >
                            <div className="flex-column justify-content-center align-items-center">
                                <Input
                                    type={"switch"}
                                    checked={globalState}
                                    onClick={() => setGlobalState(!globalState)}
                                />
                                <p>To make the offer open to all customers.</p>
                            </div>
                        </FormGroup>
                    </Col> : <Col xxl={4} md={6}></Col>}
                    <Col xxl={4} md={6}>
                        <LabelText htmlFor={"categories"} displayText={"Categories"} />
                        <CreatableSelectField className={(errors.categories && touched.categories) || false ? 'error-input' : ''} values={values.categories} placeholder={"Enter your Categories"} handleChange={(value) => {
                            setFieldValue(`categories`, value, false);
                            setFieldTouched(`categories`, false);
                        }}
                            onKeyDown={(event) => {
                                const key = event.key;
                                const regex = /^[a-zA-Z0-9]$/;
                                if (regex.test(key) || ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter'].includes(key)) {
                                    return;
                                }
                                event.preventDefault();
                            }}
                            isMulti={true}
                            name={"categories"}
                            fieldName={"categories"}
                            setFieldValue={setFieldValue}
                            errors={errors.categories}
                            isClearable={true}
                            handleBlur={handleBlur}
                            touched={touched.categories}
                        />
                    </Col>
                    <Col xxl={4} md={6}>
                    <LabelText htmlFor={"offerPriority"} displayText={"Priority"} /> <LabelText displayText={"(A higher number indicates higher priority)"} classLabelName={"numeric-code"}/>
                    <InputField name={"offerPriority"} disabled={isSubscribed ? true : false} placeholder={"Enter priority number"} type={"number"} className={`form-control ${isSubscribed ? "label-disable-color-edit-offer" : ""}`} id={"offerPriority"} handleChange={handleChange} handleBlur={handleBlur} value={values.offerPriority} />
                    </Col>
                    <Col className="mt-4" xxl={4} md={6}>
                        <LabelText htmlFor={"offerExpire"} className={"text-danger"} displayText={"Offer never expires"} check />
                        <FormGroup switch >
                            <div className="flex-column justify-content-center align-items-center">
                                <Input
                                    type={"switch"}
                                    checked={state}
                                    onClick={() => {
                                        setState(!state)
                                    }}
                                />
                                <p>To make the offer not end.</p>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xxl={12} md={12}>
                        <LabelText htmlFor={"description"} className={"text-danger"} displayText={"Description"} />
                        <InputField name={"description"} placeholder={"Write a description of the offer"} style={{ height: "100px" }} type={"textarea"} className={`form-control`} id={"description"} handleChange={handleChange} handleBlur={handleBlur} value={values.description}
                        />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}