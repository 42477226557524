import { Card, CardBody, CardHeader, Col, Row } from "reactstrap"
import { CreatableSelectField } from "../Utils/CreatableSelectField";

export const CustomerCategory = ({ setFieldError, values, errors, touched, setFieldTouched, setFieldValue, handleBlur }) => {
    return (
        <Card>
            <CardHeader>
                <h4 className="card-title mb-0 flex-grow-1">Categories<span className="numeric-code fs-6"> (Only one type of tag is allowed)</span></h4>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col xxl={6} md={6}>
                        <CreatableSelectField className={(errors.categories && touched.categories) || false ? 'error-input' : ''} values={values.categories} placeholder={"Enter your Categories"} handleChange={(value) => {
                            if (value.length <= 1) {
                                setFieldValue(`categories`, value, false);
                                setFieldTouched(`categories`, false);
                            }
                        }}
                            onKeyDown={(event) => {
                                const key = event.key;
                                const regex = /^[a-zA-Z0-9]$/;
                                if (regex.test(key) || ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter'].includes(key)) {
                                    return;
                                }
                                event.preventDefault();
                            }}
                            isMulti={true}
                            name={"categories"}
                            fieldName={"categories"}
                            setFieldValue={setFieldValue}
                            errors={errors.categories}
                            isClearable={true}
                            handleBlur={handleBlur}
                            touched={touched.categories}
                        />
                        {(errors.categories) && <p className="text-danger validation-font-size">
                            You can select only up to 1 options
                        </p>}
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}