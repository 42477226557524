import { download, generateCsv } from "export-to-csv";
import { csvConfig } from "../../Utils/ExportToCsv";

export const handleExportDataForUser = (data) => {
    if (data && data.length > 0) {
        const formattedData = data.map((item) => ({
            UserId: item.userDetails?.userId || '',
            UserName: item.userDetails?.userName || '',
            FullName: item.profile?.firstName || '',
            LastName: item.profile?.lastName || '',
            UserRole: item.role || '',
            Email: item.email || '',
            Phone: item.phone || '',
            status: item.status || '',
        }));
        const csv = generateCsv(csvConfig)(formattedData);
        download(csvConfig)(csv);
    }
};