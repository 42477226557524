import React, { useEffect, useRef, useState } from 'react';
import { Card, CardBody, Col, Row, FormGroup, CardHeader, Button, TabContent, TabPane, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import formSchema from '../Utils/schema.json';
import { RenderField } from '../Utils/renderFields';
import { RenderErrorMessage } from '../Utils/renderErrorMessages';
import { LabelText } from '../Utils/Input';
import { userOptions } from './Constant/UserOptions';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import CommonModal from '../Utils/CommonModal';
import { handleChangePassword, handleRemoveLogo } from './Constant/JsonConstant';
import { renderStatusBadge } from './Constant/StatusBadge';
import { handleModalAction } from './Constant/handleModalAction';

export default function UserDetails({ profileData, setFileSelected, values, handleChange, handleBlur, errors, touched, setFieldTouched, setFieldValue, isEdit, user, userId, navigate, dispatch, setLoading, changePassword, imageOn, setImageOn, logoFile, setLogoFile, profile, setUpdate, documentName }) {
    const [activeTab, setActiveTab] = useState('1');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [status, setStatus] = useState(user?.status || 'Active');
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const toggleModal = () => setModalOpen(!modalOpen);
    const fileInputRef = useRef(null);
    const roleName = localStorage.getItem("roleName")
    const userOptionsData = userOptions(roleName)
    const handleStatusChange = (newStatus) => {
        setStatus(newStatus);
        toggleModal();
    };
    useEffect(() => {
        if (changePassword) {
            setActiveTab('2');
        }
    }, [changePassword]);
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setLogoFile(file);
            setImageOn(true);
            setFileSelected(true)
        } else {
            setLogoFile(null);
            setImageOn(false);
            setFileSelected(false)
        }
    };
    return (
        <div className="live-preview">
            <TabContent activeTab={activeTab}>
                {!changePassword && (
                    <TabPane tabId="1" className='mt-3'>
                        {formSchema.map((section, sectionIndex) => (
                            <React.Fragment key={sectionIndex}>
                                {section.title === "User" && section.sections &&
                                    section.sections.map((subSection, subSectionIndex) => (
                                        subSection.sectionTitle !== "Password Change" && (
                                            <Card key={subSectionIndex}>
                                                {subSection?.sectionTitle && (
                                                    <>
                                                        {isEdit && <CardHeader className="align-items-center d-flex">
                                                            <h4 className="card-title mb-0 flex-grow-1 d-flex justify-content-between align-items-center">
                                                                {isEdit && subSection?.sectionTitle === "User Information" && (
                                                                    <>
                                                                        <div>
                                                                            {subSection?.sectionTitle}
                                                                            <span style={{ marginLeft: '10px' }}>{renderStatusBadge(user?.status)}</span>
                                                                        </div>
                                                                        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                                                            <DropdownToggle caret>
                                                                                Action
                                                                            </DropdownToggle>
                                                                            <DropdownMenu className='dropdown-user-action'>
                                                                                <DropdownItem onClick={() => handleStatusChange('Active')} className=' dropdown-user-action-item'>Active</DropdownItem>
                                                                                <DropdownItem onClick={() => handleStatusChange('Inactive')} className='dropdown-user-action-item'>Inactive</DropdownItem>
                                                                            </DropdownMenu>
                                                                        </Dropdown>
                                                                    </>
                                                                )}
                                                            </h4>
                                                        </CardHeader>}
                                                    </>
                                                )}
                                                <CardBody>
                                                    <Row className='gy-4'>
                                                        <Col lg={8}>
                                                            <Row className='gy-4'>
                                                                {subSection.fields.filter(field => {
                                                                    if (isEdit && (field.name === "username" || field.name === "email" || field.name === "userrole")) {
                                                                        field.disable = true;
                                                                    } else {
                                                                        field.disable = false;
                                                                    }
                                                                    if (field.name === "status" && !isEdit) {
                                                                        return false;
                                                                    }
                                                                    return true;
                                                                }).map((field, fieldIndex) => (
                                                                    <Col xxl={6} md={6} lg={6} key={`${subSectionIndex}-${fieldIndex}`} >
                                                                        <LabelText htmlFor={field.name} className={field.required ? "text-danger" : ""} displayText={field.label} important={field.required ? "*" : ""} />
                                                                        {field.type === 'phone' ? (
                                                                            <FormGroup>
                                                                                <PhoneInput
                                                                                    international
                                                                                    countryCallingCodeEditable={false}
                                                                                    className={`form-control ${touched[field.name] && errors[field.name] ? 'error-input' : ''}`}
                                                                                    placeholder={field.placeholder}
                                                                                    value={values[field.name]}
                                                                                    onChange={(value) => setFieldValue(field.name, value)}
                                                                                    onBlur={() => setFieldTouched(field.name, true)}
                                                                                    disabled={field?.autoload && field.isEdit}
                                                                                />
                                                                                {touched[field.name] && errors[field.name] && (
                                                                                    <div className="text-danger validation-font-size">
                                                                                        {errors[field.name]}
                                                                                    </div>
                                                                                )}
                                                                            </FormGroup>
                                                                        ) : (
                                                                            <>
                                                                                <RenderField
                                                                                    field={field}
                                                                                    handleChange={handleChange}
                                                                                    handleBlur={handleBlur}
                                                                                    values={values}
                                                                                    touched={touched}
                                                                                    errors={errors}
                                                                                    setFieldTouched={setFieldTouched}
                                                                                    setFieldValue={setFieldValue}
                                                                                    options={userOptionsData}
                                                                                />
                                                                                <RenderErrorMessage
                                                                                    field={field}
                                                                                    touched={touched}
                                                                                    errors={errors}
                                                                                />
                                                                            </>
                                                                        )}
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <FormGroup >
                                                                <div className='image-div-tag'>
                                                                    <LabelText htmlFor={"logo"} classLabelName={"ms-3"} displayText={"Profile Picture"} />
                                                                    <div className='image-border-style'>
                                                                        {logoFile ? (
                                                                            <>
                                                                                <img
                                                                                    src={imageOn ? URL.createObjectURL(logoFile) : logoFile}
                                                                                    alt="Logo Preview"
                                                                                    className='to-img-tag'
                                                                                />
                                                                                <button
                                                                                    onClick={() => handleRemoveLogo(dispatch, setLoading, setLogoFile, setImageOn, setUpdate, fileInputRef, documentName, userId, isEdit)}
                                                                                    className='img-remove-button'
                                                                                >
                                                                                    <span className='x-mark'>×</span>
                                                                                </button>
                                                                            </>
                                                                        ) : (
                                                                            <label htmlFor="logoInput" className='cursor-img'>
                                                                                <i className='ri-image-add-line align-middle me-1 image-icon-color'></i>
                                                                                <div><span className='text-danger'>*</span><span className='image-text-color'>File supported png., jpg., jpeg.</span></div>
                                                                            </label>
                                                                        )}
                                                                        <input type="file" id="logoInput" name="logo" accept=".jpg, .jpeg, .png" style={{ display: 'none' }} onChange={handleFileChange} ref={fileInputRef} />
                                                                    </div>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        )
                                    ))}
                            </React.Fragment>
                        ))}
                    </TabPane>
                )}
                {changePassword && (
                    <TabPane tabId="2" className='mt-3'>
                        {formSchema.map((section, sectionIndex) => (
                            <React.Fragment key={sectionIndex}>
                                {section.title === "User" && section.sections &&
                                    section.sections.map((subSection, subSectionIndex) => (
                                        subSection.sectionTitle === "Password Change" && (
                                            <Card key={subSectionIndex}>
                                                {subSection?.sectionTitle && (
                                                    <CardHeader className="align-items-center d-flex">
                                                        <h4 className="card-title mb-0 flex-grow-1">
                                                            {subSection?.sectionTitle}
                                                        </h4>
                                                    </CardHeader>
                                                )}
                                                <CardBody className='mb-2'>
                                                    <Row className='gy-4'>
                                                        <div>Enter a new password below to change your password.</div>
                                                        {subSection.fields.map((field, fieldIndex) => (
                                                            <Col xxl={4} md={6} lg={4} key={`${subSectionIndex}-${fieldIndex}`} >
                                                                <LabelText htmlFor={field.name} className={field.required ? "text-danger" : ""} displayText={field.label} important={field.required ? "*" : ""} />
                                                                <RenderField
                                                                    field={field}
                                                                    handleChange={handleChange}
                                                                    handleBlur={handleBlur}
                                                                    values={values}
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    setFieldTouched={setFieldTouched}
                                                                    setFieldValue={setFieldValue}
                                                                    options={userOptionsData}
                                                                />
                                                                <RenderErrorMessage
                                                                    field={field}
                                                                    touched={touched}
                                                                    errors={errors}
                                                                />
                                                            </Col>
                                                        ))}
                                                        <Col lg={4} className="d-flex align-items-end">
                                                            <Button color="primary" type='submit' onClick={() => { if (changePassword && values.newPassword !== "" && values.confirmNewPassword !== "") { handleChangePassword(values, dispatch, setLoading, navigate, userId) } }}>
                                                                Change Password
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        )
                                    ))}
                            </React.Fragment>
                        ))}
                    </TabPane>
                )}
            </TabContent>
            <CommonModal
                toggle={toggleModal}
                open={modalOpen}
                messageForPopUp={`Are you sure you want to change the status to ${status}?`}
                buttonText="Confirm"
                modalAction={() =>
                    handleModalAction({ values, status, setLoading, navigate, userId, dispatch, toggleModal })}
                closeButtonText="Close"
                fromCustomer=""
                closeButtonAction={toggleModal}
            />
        </div>
    );
}
