import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { Loader } from "../../Components/Utils/Loader";
import { createEnquiry } from "../../slices/EnquiryLandingPage/thunk";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import icon1 from "../../assets/images/icon1.png"
import icon2 from "../../assets/images/icon2.png"
import icon3 from "../../assets/images/icon3.png"
import icon4 from "../../assets/images/icon4.png"

const SignUpPage = () => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const siteKeyCaptcha = window.env.REACT_APP_ENQUIRY_RECAPTCHA

    document.title = 'signup';
    // Validation schema using Yup
    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email address').required('Email is required'),
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        phoneNumber: Yup.string()
            .matches(/^\d+$/, 'Phone number must contain only digits')
            .required('Phone number is required'),
        jobTitle: Yup.string().required('Job Title is required'),
        comment: Yup.string(),
        companyName: Yup.string().required('Company Name is required'),
        numberOfEmployees: Yup.number()
            .typeError('Must be a number')
            .positive('Number must be greater than zero')
            .integer('Number must be an integer')
            .required('Number of employees is required'),
        recaptcha: Yup.string().required('Please verify that you are not a robot')
    });

    // Initial form values
    const initialValues = {
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        jobTitle: '',
        comment: '',
        companyName: '',
        numberOfEmployees: '',
        recaptcha: ''
    };

    // Form submission handler
    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        const jsonObj = {
            emailAddress: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            jobTitle: values.jobTitle,
            companyName: values.companyName,
            numberOfEmployees: parseInt(values.numberOfEmployees, 10),
            comment: values.comment
        };
        dispatch(createEnquiry(jsonObj, setLoading, navigate));
        setSubmitting(false);
        resetForm();
    };

    return (
        <div
            className="d-flex align-items-center justify-content-center"
            style={{ backgroundColor: '#2E5266', minHeight: '100vh', overflowX: 'hidden' }}
        >
            <div className="container fluid">
                {loading && <Loader />}
                <div className="row align-items-center">
                    {/* Left Section */}
                    <div className="col-lg-6 mb-4 mb-lg-0 d-flex flex-column justify-content-center text-center text-lg-start">
                        <p className="text-white fw-bold mb-3 " style={{ fontSize: '30px' }}>
                            Get a Free Demo of Blueocean’s UMP Software
                        </p>
                        <p className="text-white mb-4" style={{ fontSize: 16 }}>
                            Get a personal walkthrough of UMP with one of our product experts.
                        </p>
                        <div className="row mt-5">
                            <div className="col-md-6 mb-3">
                                <div className="d-flex align-items-start">
                                    <span className="badge rounded-circle bg-white d-flex justify-content-center align-items-center me-3" style={{ width: '15%', height: '25px' }}>
                                        <img
                                            src={icon1}
                                            alt="icon"
                                            style={{ width: '300%', height: '100%' }}
                                        />
                                    </span>
                                    <p className="text-white mb-0" style={{ fontSize: 16 }}>
                                        Cater to multiple customer segments with the same framework and solution.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="d-flex align-items-start">
                                    <span className="badge rounded-circle bg-white d-flex justify-content-center align-items-center me-3" style={{ width: '15%', height: '25px' }}>
                                        <img
                                            src={icon2}
                                            alt="icon"
                                            style={{ width: '300%', height: '100%' }}
                                        />
                                    </span>
                                    <p className="text-white mb-0" style={{ fontSize: 16 }}>
                                        Enable solutions with the right setup to suit your business needs & goals.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 mb-3 mt-md-4">
                                <div className="d-flex align-items-start">
                                    <span className="badge rounded-circle bg-white d-flex justify-content-center align-items-center me-3" style={{ width: '20%', height: '25px' }}>
                                        <img
                                            src={icon3}
                                            alt="icon"
                                            style={{ width: '300%', height: '100%' }}
                                        />
                                    </span>
                                    <p className="text-white mb-0" style={{ fontSize: 16 }}>
                                        Enable a continuous process that monitors, measures, and assesses real-time network traffic.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-6 mb-3 mt-md-4">
                                <div className="d-flex align-items-start">
                                    <span className="badge rounded-circle bg-white d-flex justify-content-center align-items-center me-3" style={{ width: '20%', height: '25px' }}>
                                        <img
                                            src={icon4}
                                            alt="icon"
                                            style={{ width: '300%', height: '100%' }}
                                        />
                                    </span>
                                    <p className="text-white mb-0" style={{ fontSize: 16 }}>
                                        Leverage AI and automation judiciously to enhance IT services, improve efficiency, speed & scalability.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Right Section */}
                    <div className="col-lg-6">
                        <div className="bg-white p-5 rounded shadow rounded-5">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ isSubmitting, errors, touched, setFieldValue }) => (
                                    <Form>
                                        {/* Email field */}
                                        <div className="mb-3">
                                            <Field
                                                type="email"
                                                name="email"
                                                className={`form-control rounded-5 ${errors.email && touched.email ? 'is-invalid' : ''}`}
                                                placeholder="Email Address"
                                            />
                                            <ErrorMessage name="email" component="div" className="text-danger ms-3 mt-2" />
                                        </div>
                                        {/* First Name & Last Name */}
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <Field
                                                    type="text"
                                                    name="firstName"
                                                    className={`form-control rounded-5 ${errors.firstName && touched.firstName ? 'is-invalid' : ''}`}
                                                    placeholder="First Name"
                                                />
                                                <ErrorMessage name="firstName" component="div" className="text-danger ms-3 mt-2" />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Field
                                                    type="text"
                                                    name="lastName"
                                                    className={`form-control rounded-5 ${errors.lastName && touched.lastName ? 'is-invalid' : ''}`}
                                                    placeholder="Last Name"
                                                />
                                                <ErrorMessage name="lastName" component="div" className="text-danger ms-3 mt-2" />
                                            </div>
                                        </div>
                                        {/* Job Title & Phone Number */}
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <Field
                                                    type="text"
                                                    name="phoneNumber"
                                                    className={`form-control rounded-5 ${errors.phoneNumber && touched.phoneNumber ? 'is-invalid' : ''}`}
                                                    placeholder="Phone Number"
                                                />
                                                <ErrorMessage name="phoneNumber" component="div" className="text-danger ms-3 mt-2" />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Field
                                                    type="text"
                                                    name="jobTitle"
                                                    className={`form-control rounded-5 ${errors.jobTitle && touched.jobTitle ? 'is-invalid' : ''}`}
                                                    placeholder="Job Title"
                                                />
                                                <ErrorMessage name="jobTitle" component="div" className="text-danger ms-3 mt-2" />
                                            </div>
                                        </div>
                                        {/* Company Name & Number of Employees */}
                                        <div className="mb-3">
                                            <Field
                                                type="text"
                                                name="companyName"
                                                className={`form-control rounded-5 ${errors.companyName && touched.companyName ? 'is-invalid' : ''}`}
                                                placeholder="Company Name"
                                            />
                                            <ErrorMessage name="companyName" component="div" className="text-danger ms-3 mt-2" />
                                        </div>
                                        <div className="mb-3">
                                            <Field
                                                type="text"
                                                name="numberOfEmployees"
                                                className={`form-control rounded-5 ${errors.numberOfEmployees && touched.numberOfEmployees ? 'is-invalid' : ''}`}
                                                placeholder="Number of Employees"
                                            />
                                            <ErrorMessage name="numberOfEmployees" component="div" className="text-danger ms-3 mt-2" />
                                        </div>
                                        {/* Comment field */}
                                        <div className="mb-3">
                                            <Field
                                                as="textarea"
                                                name="comment"
                                                className={`form-control rounded-4 ${errors.comment && touched.comment ? 'is-invalid' : ''}`}
                                                placeholder="Comment"
                                                rows="2" 
                                            />
                                        </div>
                                        {/* Google reCAPTCHA */}
                                        <div className="mb-3 d-flex justify-content-center flex-column align-items-center">
                                            <ReCAPTCHA
                                                sitekey={siteKeyCaptcha}
                                                onChange={(value) => setFieldValue("recaptcha", value)}
                                            />
                                            <div className="text-center">
                                                <ErrorMessage name="recaptcha" component="div" className="text-danger mt-2" />
                                            </div>
                                        </div>
                                        {/* Submit Button */}
                                        <div className="d-flex justify-content-center mt-2">
                                            <button
                                                type="submit"
                                                className="btn btn-primary rounded-5 "
                                                style={{ backgroundColor: '#fd7545', fontSize: '16px', width: '40%' }}
                                                disabled={isSubmitting}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUpPage;
