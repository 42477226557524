import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor";
import { setSuccessMessage } from "../toastMessage/action";
import { toast } from "react-toastify";
import { fetchAddEnquirySuccess, fetchAddEnquiryFailure } from "./reducer";
import { ENQUIRY_CREATE } from "../constants/constants";

export const createEnquiry = (jsonObj, setLoading, navigate) => (dispatch) => {
    setLoading(true)
    determineBaseUrlAndPerformApiCall(`${ENQUIRY_CREATE}`, 'POST', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchAddEnquirySuccess(response))
            navigate('/home');
            dispatch(setSuccessMessage(true))
        }
        if (response.error) {
            dispatch(fetchAddEnquiryFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}