import React, { useState } from "react";
import { Box } from "@mui/material";
import { getCellStyle } from "../../../Utils/DecideColor";
import { Input, Tooltip } from "reactstrap";
import { getConsumptionRule } from "./resourcePayload";

export const ResourcecolumnData = (isCurrency, selectAllResource, setSelectAllResource, resources, setSelectedResource, selectedResource, checkboxRef, isMobile, modalOpen) => {
  const [tooltipOpen, setTooltipOpen] = useState({});

  const toggle = (index) => {
    setTooltipOpen((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  return [
    {
      accessorKey: 'checkbox',
      header: 'checkbox',
      Header: ({ row }) => (
        <Input readOnly type="checkbox" ref={checkboxRef} className=' border-black checkbox-size rounded-1' checked={selectAllResource} style={{ borderWidth: '2px', borderColor: '#000', borderRadius: '0' }} onClick={() => {
          setSelectAllResource(!selectAllResource)
          if (!selectAllResource) {
            setSelectedResource(resources)
          }
          else {
            setSelectedResource([])
          }
        }} />
      ),
      enableColumnActions: false,
      enableColumnDragging: false,
      Cell: ({ row }) => (
        <Input readOnly type="checkbox" className=' border-black checkbox-size  rounded-1' checked={selectedResource.some(p => p.name === row.original.name)} style={{ borderWidth: '2px', borderColor: '#000', borderRadius: '0' }} onClick={() => {
          handleCheckboxChange(row, selectedResource, setSelectedResource)
        }
        } />
      ),
      size: isMobile ? 50 : modalOpen ? 32 : 24
    },
    {
      accessorKey: "name",
      header: "Resource Name",
      size: 150,
    },
    {
      accessorKey: "resourceType",
      accessorFn: (resource, index) => `${isCurrency[index]}`,
      header: "Resource Type",
      size: 150,
      Cell: ({ cell }) => (
        <Box
          sx={(theme) => ({
            ...getCellStyle(cell.getValue(), "Resource"),
          })}
        >
          {cell.getValue()}
        </Box>
      ),
    },
    {
      accessorKey: "resourceId",
      header: "Code",
      size: 150,
    },
    {
      accessorKey: "ceiling",
      header: "Ceiling",
      size: 100,
    },
    {
      accessorKey: "floor",
      header: "Floor",
      size: 100,
    },
    {
      accessorKey: "consumptionRule",
      header: "Consumption Rule",
      Cell: ({ cell }) => {
        const cellValue = getConsumptionRule(cell.getValue());
        const word = cellValue.split(" ");
        return (
          <div key={cell.row.index}>
          <p>
            <a id={`TooltipExample_${cell.row.index}`}>
              {word.slice(0, 3).join(' ')}
              {word.length > 3 && <span>...</span>}
            </a>
          </p>
          {word.length > 3 && <Tooltip className="tooltip-border-radius"
            autohide={true}
            isOpen={!!tooltipOpen[cell.row.index]}
            target={`TooltipExample_${cell.row.index}`}
            toggle={() => toggle(cell.row.index)}
          >
            {cellValue}
          </Tooltip>}
        </div>
        );
      },
      size: 200,
    },
  ];
};

export const handleCheckboxChange = (row, selectedResource, setSelectedResource) => {
  let isChecked = selectedResource.some(p => p.name === row.original.name);
  let updatedSelectedResource = isChecked
    ? selectedResource.filter(p => p.name !== row.original.name)
    : [...selectedResource, row.original];
  setSelectedResource(updatedSelectedResource);
};
