import { camelize } from "./OperatorListingJson";

export const formatValue = (value) => {
    if (typeof value === 'boolean') {
        return value ? "True" : "False";
    }
    if (value === undefined) {
        return "False"
    }
    return value;
};
export const createConfigArray = (finalValue, response) => {
    const config = {};

    const taskEnabledValue = finalValue["TaskEnabled"];
    const brmTaskEnabledValue = finalValue["BRMTaskEnabled"];

    const paymentProviderValue = finalValue["PaymentProvider"];
    const identityProviderValue = finalValue["IdentityProvider"]

    const customerSearchValue = finalValue["CustomerSearch"]
    const customerListingValue = finalValue["CustomerListing"]

    Object.entries(finalValue).forEach(([key, value]) => {
        let managementName = '';
        const managementNameMapping = {
            "payment": "PaymentManagement",
            "identity": "identityManagement",
            "whatsApp": "whatsAppManagement",
            "notification": "NotificationManagement",
            "Task": "TaskManagement",
            "policy": "policySpecifications",
            "Customer": "CustomerRetrieval",
            "self": "selfcare",
            "order": "orderManagement",
            "Billing":"BillingManagement",
            "LLM":"LLM"
        };
        function getManagementName(key) {
            let managementName = null;

            Object.entries(managementNameMapping).forEach(([prefix, name]) => {
                if (prefix === "Task" && key.includes(prefix)) {
                    managementName = name
                }
                if (prefix === "Customer" && key.includes(prefix)) {
                    managementName = name
                }
                else if (key.startsWith(prefix)) {
                    managementName = name;
                }
            });

            return managementName;
        }

        managementName = getManagementName(key)
        if (managementName) {
            if (!config[managementName]) {
                config[managementName] = {
                    name: managementName,
                    value: [],
                    operatorId: response?.data?.adminDetails?.operatorId,
                    type: response?.data?.domain,
                    level: "Operator"
                };
            }

            const managementConfig = {
                "TaskManagement": [
                    { key: "TaskEnabled", value: formatValue(taskEnabledValue) },
                    { key: "BRMTaskEnabled", value: formatValue(brmTaskEnabledValue) }
                ],
                "PaymentManagement": [
                    { key: "PaymentProvider", value: paymentProviderValue },
                    { key: "PaymentStatus", value: value ? "ACTIVE" : "INACTIVE" }
                ],
                "identityManagement": [
                    { key: "IdentityProvider", value: identityProviderValue },
                    { key: "Enable", value: value ? "True" : "False" }
                ],
                "orderManagement": [
                    { key: "OrderEnabled", value: value ? "True" : "False" }
                ],
                "NotificationManagement": [
                    { key: "NotificationEnable", value: value ? "True" : "False" }
                ],
                "CustomerRetrieval": [
                    { key: "CustomerSearch", value: formatValue(customerSearchValue) },
                    { key: "CustomerListing", value: formatValue(customerListingValue) }
                ],
                "BillingManagement":[
                    { key :"BillingEnabled", value: value ? "True": "False"}
                ],
                "LLM":[
                    { key :"LLMEnabled", value: value ? "True": "False"}
                ]
            };

            if (managementConfig[managementName]) {
                managementConfig[managementName].forEach(item => {
                    if (!config[managementName].value.some(existingItem => existingItem.key === item.key)) {
                        config[managementName].value.push({
                            key: item.key,
                            value: [item.value]
                        });
                    }
                });
            } else {
                const formattedValue = formatValue(value);
                if (!config[managementName].value.some(item => item.key === key)) {
                    config[managementName].value.push({
                        key: key,
                        value: [formattedValue]
                    });
                }
            }

        }
    });
    return Object.values(config);
};

export const combinedConfigurations = (OperatorDefaultConfigurationList) => {
    const configList = [...OperatorDefaultConfigurationList];

    const paymentIndex = configList.findIndex(config => config.name === "paymentManagement");
    const identityIndex = configList.findIndex(config => config.name === "identityManagement");

    if (paymentIndex !== -1 && identityIndex !== -1) {
        const identityConfig = configList.splice(identityIndex, 1)[0];
        configList.splice(paymentIndex + 1, 0, identityConfig);
    }

    return configList;
};

export const mapConfig = (isEdit, configData, OperatorDefaultConfigurationList, setSwitchState, setSelectState) => {
    const initialSwitchState = {};
    const initialSelectState = {};

    const config = isEdit ? configData?.configurations : OperatorDefaultConfigurationList
    config?.forEach(config => {
        const camelizedName = camelize(config.name);
        if (!isEdit) {
            initialSwitchState[camelizedName] = false;
        }
        else {
            config.value.forEach((val) => {
                const switchStateMapping = {
                    "PaymentStatus": { target: "paymentManagement", transform: (value) => value === "ACTIVE" },
                    "Enable": { target: "identityManagement", transform: (value) => value === "True" },
                    "TaskEnabled": { target: "TaskEnabled", transform: (value) => value === "True" },
                    "BRMTaskEnabled": { target: "BRMTaskEnabled", transform: (value) => value === "True" },
                    "CustomerSearch": { target: "CustomerSearch", transform: (value) => value === "True" },
                    "CustomerListing": { target: "CustomerListing", transform: (value) => value === "True" },
                    "OrderEnabled": { target: "orderManagement", transform: (value) => value === "True" },
                    "NotificationEnable": { target: "notificationManagement", transform: (value) => value === "True" },
                    "BillingEnabled": {target:"BillingManagement", transform: (value) => value === "True" },
                    [camelizedName]: { target: camelizedName, transform: (value) => value === "True" }
                };

                const selectStateMapping = {
                    "PaymentProvider": { target: "PaymentProvider", transform: (value) => ({ label: value, value: value }) },
                    "IdentityProvider": { target: "IdentityProvider", transform: (value) => ({ label: value, value: value }) }
                };

                if (switchStateMapping[val.key]) {
                    initialSwitchState[switchStateMapping[val.key].target] = switchStateMapping[val.key].transform(val?.value[0]);
                }

                if (selectStateMapping[val.key]) {
                    initialSelectState[selectStateMapping[val.key].target] = selectStateMapping[val.key].transform(val?.value[0]);
                }

            })
        }
    });

    setSwitchState(initialSwitchState);
    setSelectState(initialSelectState);
}

export const handleSwitchToggle = (configName, setUseEffectEnabled, setSwitchState) => {
    setUseEffectEnabled(true);
    setSwitchState((prevState) => {
        const newState = { ...prevState, [configName]: !prevState[configName] };

        if (configName === "CustomerSearch" && newState[configName]) {
            newState["CustomerListing"] = false;
        } else if (configName === "CustomerListing" && newState[configName]) {
            newState["CustomerSearch"] = false;
        } else if (configName === "CustomerSearch" && !newState[configName]) {
            newState["CustomerListing"] = true;
        } else if (configName === "CustomerListing" && !newState[configName]) {
            newState["CustomerSearch"] = true;
        }

        return newState;
    });
};

export const handleSelectChange = (key, selectedOption, setSelectState) => {
    setSelectState(prevState => ({
        ...prevState,
        [key]: selectedOption
    }));
};

export const brmCheck = (switchState, setSwitchState) => {
    if (!switchState?.TaskEnabled) {
        setSwitchState(prevState => ({
            ...prevState,
            BRMTaskEnabled: false
        }));
    }
}

export const customerRetrievalCheck = (useEffectEnabled, isCreate, setSwitchState, values) => {
    if (!useEffectEnabled && isCreate) {
        setSwitchState((prevState) => ({
            ...prevState,
            CustomerSearch: values?.Domain?.label === "Telco",
            CustomerListing: values?.Domain?.label !== "Telco",
        }));
    }
}
