import React, { useEffect } from 'react';
import step1 from '../../assets/images/step1.png'
import step2 from '../../assets/images/step22.png'
import step3 from '../../assets/images/step-3.png'
import step4 from '../../assets/images/step-4.png'
import step5 from '../../assets/images/step-5.png'
import step6 from '../../assets/images/step-6.png'
import backgroundImage from '../../assets/images/landingmain.jpg'
import blueoceanLogo from '../../assets/images/blueocean.png'
import macbookImage from '../../assets/images/image.png'
import icon1 from '../../assets/images/pencilrulericon.png'
import icon2 from '../../assets/images/circleicon.png'
import icon3 from '../../assets/images/settingsicon.png'
import icon4 from '../../assets/images/groupicon.png'
import Carousel from './CarosalCard';
import { setSuccessMessage } from '../../slices/toastMessage/action';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

const LandingPage = () => {
  document.title = 'Landing Page';
  const dispatch = useDispatch()
  const showSuccessMessage = useSelector((state) => state.SuccessToast.showSuccessMessage)
  const showEditSuccessMessage = useSelector((state) => state.SuccessToast.showEditSuccessMessage)

  useEffect(() => {
      if (showSuccessMessage) {
          toast.success(`Thank you for your message. It has been sent.`);
          dispatch(setSuccessMessage(false))
      }
  }, [showSuccessMessage, dispatch]);

  return (
    <>
    <ToastContainer position='top-center' />
      <header className="bg-white shadow-sm py-2">
        <div className="px-3 d-flex justify-content-between align-items-center">
          <img
            src={blueoceanLogo}
            width="137px"
            height="39px"
            alt="Blueocean Logo"
            className="logo"
          />
          <a
            href="/login"
            className="text-black fw-semibold text-decoration-none d-inline-flex align-items-center"
          >
            Sign In
            <span className="ms-2">
              <i className="ri-login-box-line fs-4 main-color" />
            </span>
          </a>
        </div>
      </header>
      <section
        className="hero text-white text-center py-5"
        style={{ position: "relative", backgroundColor: "#244a63" }}
      >
        <div
          className="hero-bg"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            opacity: "0.1",
            zIndex: 1
          }}
        />
        <div className="containers position-relative p-2" style={{ zIndex: 2 }}>
          <h1 className=' text-white fw-semibold'>Usage Management Platform</h1>
          <p className="sub-heading fw-medium">Empowering Usage-Driven Businesses</p>
          <a
            href="/enquiry"
            className="btn text-white main-bg-colour px-4 py-2 fw-bold fs-5 rounded rounded-5 mt-3"
            style={{ width: 249 }}
          >
            Book a demo
          </a>
          <div className="mt-4">
            {/* Adjust spacing here */}
            <img
              src={macbookImage}
              width="550px"
              alt="Dashboard Mockup"
              className="img-fluid"
            />
          </div>
          <div className="mt-4">
            <p className="fw-medium" style={{ fontSize: 16 }}>
              Your One-Stop Platform for Real-Time Usage Management Across
              Industries <br />
              <span className="sub-heading-color">
                From telecom to energy and beyond—unlock the power of real-time
                usage management tailored to your business needs.
                <br />
                Simplify complexity, scale effortlessly, and gain a competitive edge
                with a future-ready platform.
              </span>
            </p>
          </div>
        </div>
      </section>
      <div>
        <div
          style={{
            background: "linear-gradient(103.37deg, #027596 1.08%, #019ca0 99.02%)",
            justifyItems: "center",
            paddingBottom: 10
          }}
        >
          <section
            className="transform py-5"
            style={{
              width: "90%",
              backgroundColor: "rgb(255, 255, 255)",
              height: "120%",
              position: "relative",
              bottom: 40,
              zIndex: 10,
              borderBottom: "16px solid #fd7545"
            }}
          >
            <div className="containers text-center ">
              <h1 className="fw-semibold title-heading-color p-2" style={{ fontSize: 40 }}>
                Transform How You Manage <span className="main-color">Usage</span>
              </h1>
              <p className="fw-bold mb-3" style={{ fontSize: 18 }}>
                A modern solution built to adapt
              </p>
              <p className="text-black fw-medium p-4 text-justify" style={{ fontSize: "16px", cssText: "font-size: 16px !important;" }}>
                UMP is a versatile platform engineered to rate and manage usage data
                from any industry. Whether it’s powering electric vehicles,
                optimizing telecom networks, managing energy consumption, or
                allocating broadband resources, UMP provides a unified platform that
                drives smarter decisions and streamlines operations
              </p>
            </div>
            <div className="containers text-center">
              <p className="fw-semibold title-heading-color" style={{ fontSize: 35 }}>
                The Business Advantage of <span className="main-color">UMP</span>
              </p>
              <p className="text-black fw-bold mt-2 mb-4" style={{ fontSize: "16px", cssText: "font-size: 16px !important;" }}>
                Unlock the Full Potential of Your Network with Advanced Capabilities
              </p>
              <Carousel />
            </div>
          </section>
        </div>
      </div>
      <div className="bg-white pt-5 text-center journey-section">
        <div className="containers mt-4 p-3">
          {/* Title Section */}
          <h2 className="journey-title" style={{ fontSize: 35 }}>
            Your Seamless Journey to
            <span className="main-color"> Smarter Operations</span>
          </h2>
          <p className="journey-subtitle text-black" style={{ fontSize: "16px", cssText: "font-size: 16px !important;" }}>
            Empowering businesses with unmatched speed, flexibility, and compliance
            for a competitive edge
          </p>
          {/* Step 1 */}
          <div className="row justify-content-center align-items-center mt-5 step-row">
            {/* Image */}
            <div className="col-md-6 text-center mb-4">
              <img
                src={step1}
                alt="Step 1"
                className="img-fluid step-image rounded"
              />
            </div>
            {/* Content */}
            <div className="col-md-5 text-start">
              <div className="ms-md-4 d-flex">
                <div className="step-circle  mx-md-2 col-md-3">1</div>
                <div className="flex-column">
                  <h5 className="step-title" style={{ fontSize: "20px" }}>
                    Design Usage-Based Plans
                    <span className=' break-text'> Into Your Catalog</span>
                  </h5>
                  <p className="fw-medium" style={{ fontSize: "16px", cssText: "font-size: 16px !important;" }}>
                  Craft flexible, dynamic plans tailored to your business model
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Step 2 */}
          <div className="row justify-content-center align-items-center mt-5 flex-md-row-reverse step-row">
            {/* Image */}
            <div className="col-md-6 text-start mb-4">
              <img
                src={step2}
                alt="Step 2"
                className="img-fluid step-image rounded"
              />
            </div>
            {/* Content */}
            <div className="col-md-5 text-start">
              <div className="responsive-margin-1 d-flex">
                <div className="step-circle-1 mx-md-2 col-md-3">2</div>
                <div className="flex-column">
                  <h5 className="step-title mt-md-2" style={{ fontSize: "20px" }}>
                    Seamlessly Onboard Customers
                  </h5>
                  <p className="fw-medium" style={{ fontSize: "16px", cssText: "font-size: 16px !important;" }}>
                  Enable smooth onboarding for customers, partners, and stakeholders with an intuitive multi-tenant architecture
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Step 3 */}
          <div className="row justify-content-center align-items-center mt-5 step-row">
            {/* Image */}
            <div className="col-md-6 text-center mb-4">
              <img
                src={step3}
                alt="Step 3"
                className="img-fluid step-image rounded"
              />
            </div>
            {/* Content */}
            <div className="col-md-5 text-start">
              <div className="ms-md-4 d-flex">
                <div className="step-circle-2 mx-md-2 col-md-3">3</div>
                <div className="flex-column">
                  <h5 className="step-title mt-md-2" style={{ fontSize: "20px" }}>
                    Activate Industry Connections
                  </h5>
                  <p className="fw-medium" style={{ fontSize: "16px", cssText: "font-size: 16px !important;" }}>
                  Effortlessly integrate with ready-to-use industry ecosystems to enable streamlined operations
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Step 4 */}
          <div className="row justify-content-center align-items-center mt-5 flex-md-row-reverse step-row">
            {/* Image */}
            <div className="col-md-6 text-start mb-4">
              <img
                src={step4}
                alt="Step 4"
                className="img-fluid step-image rounded"
              />
            </div>
            {/* Content */}
            <div className="col-md-5 text-start">
              <div className="responsive-margin-1 d-flex">
                <div className="step-circle-3 mx-md-2 col-md-3">4</div>
                <div className="flex-column">
                  <h5 className="step-title mt-md-2" style={{ fontSize: "20px" }}>
                    Process and Input Usage Data
                  </h5>
                  <p className="fw-medium" style={{ fontSize: "16px", cssText: "font-size: 16px !important;" }}>
                  Feed the platform with real-time data whether it’s transactions, activity logs, or resource consumption unlocking actionable insights
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Step 5 */}
          <div className="row justify-content-center align-items-center mt-5 step-row">
            {/* Image */}
            <div className="col-md-6 text-center mb-4">
              <img
                src={step5}
                alt="Step 5"
                className="img-fluid step-image rounded"
              />
            </div>
            {/* Content */}
            <div className="col-md-5 text-start">
              <div className="ms-md-4 d-flex">
                <div className="step-circle-4 mx-md-2 col-md-3">5</div>
                <div className="flex-column">
                  <h5 className="step-title mt-md-2" style={{ fontSize: "20px" }}>
                    Real-Time Usage Processing
                  </h5>
                  <p className="fw-medium" style={{ fontSize: "16px", cssText: "font-size: 16px !important;" }}>
                  Harness UMP’s precision to rate usage data in real time, enhancing billing accuracy and operational speed
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Step 6 */}
          <div className="row justify-content-center align-items-center mt-5 flex-md-row-reverse step-row">
            {/* Image */}
            <div className="col-md-6 text-start mb-4">
              <img
                src={step6}
                alt="Step 6"
                className="img-fluid step-image rounded"
              />
            </div>
            {/* Content */}
            <div className="col-md-5 text-start">
              <div className="responsive-margin-1 d-flex">
                <div className="step-circle-5 mx-md-2 col-md-3">6</div>
                <div className="flex-column">
                  <h5 className="step-title mt-md-2" style={{ fontSize: "20px" }}>
                    Deliver Business Insights
                  </h5>
                  <p className="fw-medium" style={{ fontSize: "16px", cssText: "font-size: 16px !important;" }}>
                  Generate rated outputs that fuel flawless billing, reporting, and operational planning
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          background: "linear-gradient(103.37deg, #027596 1.08%, #019ca0 99.02%)",
          paddingBottom: 40,
          marginTop: "-90px"
        }}
      >
        <div className="py-3" />
        <div className="containers text-center p-4">
          <p className="fw-semibold text-white" style={{ fontSize: 35 }}>
            UMP: Redefining Excellence in
            <span className="sub-heading-color-landing"> Usage Management</span>
          </p>
          <div className="row justify-content-center text-white mt-5">
            {/* Row 1 */}
            <div className='col-md-1'></div>
            <div className="col-md-4 d-flex flex-column align-items-center mb-4">
              <div className="circle-icon mb-3">
                <img
                  src={icon1}
                  alt="Icon 1"
                  className="icon-image"
                />
              </div>
              <h5 className="fw-bold text-white">Built for the Future</h5>
              <p className="text-center sub-heading-color fw-medium" style={{ fontSize: "16px", cssText: "font-size: 16px !important;" }}>
                Adaptable to diverse industries, UMP offers unparalleled flexibility
                and scalability
              </p>
            </div>
            <div className='col-md-1'></div>
            <div className="col-md-4 d-flex flex-column align-items-center mb-4">
              <div className="circle-icon mb-3">
                <img src={icon2} alt="Icon 2" className="icon-image" />
              </div>
              <h5 className="fw-bold text-white">Real-Time Precision</h5>
              <p className="text-center sub-heading-color fw-medium" style={{ fontSize: "16px", cssText: "font-size: 16px !important;" }}>
                Instant, accurate usage rating ensures transparency and reliability
              </p>
            </div>
            <div className='col-md-1'></div>
          </div>
          <div className="row justify-content-center text-white mt-2">
            {/* Row 2 */}
            <div className='col-md-1'></div>
            <div className="col-md-4 d-flex flex-column align-items-center mb-4">
              <div className="circle-icon mb-3">
                <img src={icon3} alt="Icon 3" className="icon-image" />
              </div>
              <h5 className="fw-bold text-white">Effortless Integration</h5>
              <p className="text-center sub-heading-color fw-medium" style={{ fontSize: "16px", cssText: "font-size: 16px !important;" }}>
                Designed to work seamlessly with your existing systems, UMP empowers
                smooth operations
              </p>
            </div>
            <div className='col-md-1'></div>
            <div className="col-md-4 d-flex flex-column align-items-center mb-4">
              <div className="circle-icon mb-3">
                <img src={icon4} alt="Icon 4" className="icon-image" />
              </div>
              <h5 className="fw-bold text-white">Customer-First Focus</h5>
              <p className="text-center sub-heading-color fw-medium" style={{ fontSize: "16px", cssText: "font-size: 16px !important;" }}>
                Enhance end-user satisfaction with personalized offerings and
                real-time updates
              </p>
            </div>
            <div className='col-md-1'></div>
          </div>
        </div>
      </div>
      <div style={{ background: "#113045", border: "35px solid #e4e4e4" }}>
        <div className="containers text-center mt-5">
          <p className="fw-semibold text-white" style={{ fontSize: 35 }}>
            Ready to Transform Your Business?
          </p>
          <div className="d-flex justify-content-center">
            <p className=" sub-heading-color fw-medium text-center w-75 mx-auto" style={{ fontSize: "16px", cssText: "font-size: 16px !important;" }}>
              Join the industry’s most forward-thinking organizations leveraging UMP
              to optimize usage, enhance efficiency, and scale effortlessly.
              <span className="text-white"> Request a Demo </span> today and unlock
              your business’s true potential
            </p>
          </div>

          <a
            href="/enquiry"
            className="btn text-white main-bg-colour px-4 py-2 fw-bold fs-5 rounded rounded-5 mt-3 mb-5"
            style={{ width: 249 }}
          >
            Book a demo
          </a>
        </div>
      </div>
      <div className="p-3 text-center text-md-start" style={{ fontSize: 14 }}>
        <p className="text-black fw-semibold">
          © 2024, Blueocean. All rights reserved.
        </p>
      </div>
    </>
  );
};

export default LandingPage;
