import { fetchDetailedBalanceFailure, fetchDetailedBalanceSuccess } from "./reducer";
import moment from "moment";
import { BALANCE_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";

export const fetchDetailedBalance = (id, setLoading, inCustomerList, navigate, setModalForSimulate, isView, toggleModal) => async (dispatch) => {
    setLoading(true)
    const time = moment().format('YYYY-MM-DDTHH:mm:ss[Z]');
    let uniqueId
    if (inCustomerList) {
        uniqueId = id.original.CustomerId
    } else {
        uniqueId = id
    }
    await determineBaseUrlAndPerformApiCall(`${BALANCE_API}/${uniqueId}?mode=summary&startTime=${time}`, 'GET').then((response) => {
        if (response.error) {
            setLoading(false)
            dispatch(fetchDetailedBalanceFailure(response.error.messsage))
            dispatch(fetchDetailedBalanceSuccess([]))
            if (!isView) {
                setModalForSimulate(true)
                toggleModal(id?.original?.CustomerId, "single");
            }
        }
        if (!response.error) {
            setLoading(false)
            dispatch(fetchDetailedBalanceSuccess(response))
            if (inCustomerList) {
                navigate(`/simulate/${id?.original?.CustomerId}`, { state: { Name: id?.original?.Name } })
                setModalForSimulate(false)
            }
        }
    })
}