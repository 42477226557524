export const getCellStyle = (cellValue, module) => {
    let style = {};
    if (module !== "Service") {
        style.borderRadius = (module === "InvoiceForCustomer" || module === "InvoiceForAll" || module === "Device") ? '0.65rem' : (module === "BillsForCustomer" || module === "BillsForPartner") ? "0.60rem" : module === "Resource" ? "0.55rem" : '0.35rem'
        style.maxWidth = (module === "InvoiceForCustomer" || module === "InvoiceForAll") ? "auto" : module === "Device" ? "12ch" : (module === "ViewOrder" || module === "CreateOrder") ? "13ch" : module === "OverallOrders" ? "15ch" : module === "Resource" ? "14ch" : (module === "Task" && cellValue === 'NOT_ENABLED') ? '16ch' : module === "User" ? '12ch' : module === "Task" ? '10ch' : '9ch'
        style.display = "flex"
        style.justifyContent = "center"
        style.alignItems = "center"
        style.padding = module === "OverallOrders" ? "0px" : module === "Resource" ? "5px" : "1px"
        style.minWidth = module === "User" ? '14ch' : '0ch'
        if (module === "Resource") {
            style.fontSize = "14px"
        }
    }
    if (module === "Tags") {
        style.border = `2px solid #0078A3`
        style.color = `#0078A3`
        style.padding = "3px"
    } else {
        switch (cellValue) {
            case 'validated':
            case 'Approved':
            case 'close':
            case 'paid':
            case 'Assigned':
            case 'completed':
            case 'Accepted':
            case 'Active':
            case 'active':
            case 'ACTIVE':
            case 'SUCCESS':
            case 'Success':
            case 'open':
                style.border = '2px solid #0AB39C';
                style.color = '#0AB39C';
                break;
            case 'closed':
            case 'Rejected':
            case 'cancelled':
            case 'Closed':
            case 'failed':
            case 'Failed':
            case 'Terminated':
            case 'Inactive':
            case 'inactive':
            case 'IN-ACTIVE':
            case 'FAILED':
            case 'CANCEL':
                style.border = '2px solid #FF2D00';
                style.color = '#FF2D00';
                break;
            case 'initialized':
            case 'partially_paid':
            case 'unpaid':
            case 'Unassigned':
            case 'acknowledged':
            case 'Acknowledged':
            case 'In Progress':
            case 'inProgress':
            case 'INPROGRESS':
            case 'pending':
            case 'PENDING':
            case 'In Progress' && module === "Task":
            case 'Unverified':
            case 'Unregistered':
            case 'Pending':
                style.border = '2px solid #F6B84B';
                style.color = '#F6B84B';
                break;
            case 'Provisioning':
            case 'Usage':
            case 'Charge':
            case 'Grant':
                style.border = `2px solid ${module !== "Offer" && module !== "Service" ? '#3478F1' : '#0078A3'}`
                style.color = `${module !== "Offer" && module !== "Service" ? '#3478F1' : '#0078A3'}`
                break;
            case 'Non-Monetary':
            case 'Monetary':
                style.border = `2px solid #0078A3`
                style.color = `#0078A3`
                break;
            case 'Base':
            case 'Add On':
                style.border = `2px solid #0078A3`
                style.color = `#0078A3`
            case 'NOT_ENABLED':
                style.border = `2px solid #878a99`
                style.color = `#878a99`
            default:
                break;
        }
    }

    return style;
}
