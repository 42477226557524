import React, { useState, useEffect } from 'react';
import { Card, CardBody, Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import BreadCrumb from '../Common/BreadCrumb';
import { Loader } from '../Utils/Loader';
import { useOfferServiceData } from './constants/useOfferServiceData';
import { PAGE_TITLES } from '../Common/constants';
import { filteredResultFunction, groupedFunction, toggleAccordion } from './constants/OrderFilterFunctions';
import { OrderFilterAccordion } from './OrderFilterAccordion';
import classnames from "classnames";
import { tabChange } from "../Utils/SetDefaultRowData";

const OrderDetail = () => {
    const location = useLocation();
    const customerIdPath = location.pathname.slice(location.pathname.lastIndexOf("/"), location.pathname.length);
    const addOnState = location.pathname.includes('/add-on');
    const customerId = customerIdPath.slice(1, customerIdPath.length);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const orderId = location.state?.orderId || '';
    const { offerResponse, serviceResponse, customerData, resourceData } = useOfferServiceData(customerId, setLoading);
    const [open, setOpen] = useState('1');
    const toggle = toggleAccordion(open, setOpen);
    const [result, setResult] = useState({ baseOffers: [], addOnOffers: [] });
    const [searchTerm, setSearchTerm] = useState('');
    const [activeTab, setActiveTab] = useState("1");
    const { subscriptionId } = location.state || {};
    useEffect(() => {
        groupedFunction(offerResponse, serviceResponse, resourceData, setResult, customerId, customerData, orderId);
    }, [offerResponse, serviceResponse]);
    const filteredResultsBase = filteredResultFunction(result.baseOffers, searchTerm);
    const filteredResultsAddOn = filteredResultFunction(result.addOnOffers, searchTerm);
    if (addOnState === true) {
        tabChange("2", activeTab, setActiveTab)
    }
    return (
        <React.Fragment>
            {loading && (<Loader data-testid="loader" />)}
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title={PAGE_TITLES.CUSTOMER_SUBSCRIBE} pageTitle={PAGE_TITLES.CUSTOMER_LIST} />
                    <ToastContainer position='top-center' />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>

                                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">

                                        <NavItem>
                                            {!addOnState ?
                                                <NavLink
                                                    className={classnames({ active: activeTab === "1" })}
                                                    onClick={() => tabChange("1", activeTab, setActiveTab)}>
                                                    <i className="fas fa-home"></i>
                                                    Base
                                                </NavLink> : null}
                                        </NavItem>
                                        <NavItem>
                                            {addOnState ?
                                            <NavLink
                                                className={classnames({ active: activeTab === "2" })}
                                                onClick={() => tabChange("2", activeTab, setActiveTab)}>
                                                <i className="far fa-user"></i>
                                                Add-On
                                            </NavLink>:null}
                                        </NavItem>
                                    </Nav>
                                    <div className='row mt-4'>
                                        <div className='col-xl-9 col-md-8 text-md-end'>
                                            <div className='search-box'>
                                                <span className='ri-search-line search-icon'></span>
                                                <input
                                                    type='text'
                                                    className='form-control bg-light'
                                                    placeholder='Search...'
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardBody>
                                    {activeTab === "1" && (
                                        <OrderFilterAccordion
                                            open={open}
                                            toggle={toggle}
                                            filteredResults={filteredResultsBase}
                                            loading={loading}
                                            setLoading={setLoading}
                                            navigate={navigate}
                                        />
                                    )}
                                    {activeTab === "2" && (
                                        <OrderFilterAccordion
                                            open={open}
                                            toggle={toggle}
                                            filteredResults={filteredResultsAddOn}
                                            loading={loading}
                                            setLoading={setLoading}
                                            navigate={navigate}
                                            subscriptionId={subscriptionId}
                                        />
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default OrderDetail;
