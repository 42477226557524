import { useState } from "react";
import React from "react";
import { MaterialReactTable } from "material-react-table";
import { MenuItem, Popover } from '@mui/material';
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { unsubscribeOffer } from "./constants/DocumentTableFunctions";
import CommonModal from "../Utils/CommonModal";
import { Loader } from "../Utils/Loader";
import { Box } from "@mui/material";
import { getStatusLabel } from "../Utils/DecideStatus";
import { getCellStyle } from "../Utils/DecideColor";
import { useNavigate } from "react-router-dom";
import { customerIdData, showPopUp } from "./constants/constantFunctionsView";
import moment from "moment";
import { subscriptionColumns } from "./constants/SubscriptionColoumn";
export default function ViewSubscription({ offer, customerData, subscriptionPermission }) {
    const [subscriptionId, setSubscriptionId] = useState('');
    const [messageForPopUp, setMessageForPopUp] = useState('');
    const [loading, setLoading] = useState(false);
    const [openPopUp, setOpenPopUp] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const isPopoverOpen = Boolean(anchorEl);
    const customerId = customerIdData();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classifiedOffers = customerData.subscriptionList?.map(subscription => {
        const baseOffers = subscription.subscribedOffers
            .filter(offer => !offer.addOnOffer)
            .map(baseOffer => ({ ...baseOffer, subscriptionId: subscription.subscriptionId }));
        const addonOffers = subscription.subscribedOffers
            .filter(offer => offer.addOnOffer)
            .map(addonOffer => ({ ...addonOffer, subscriptionId: subscription.subscriptionId }));
        return { baseOffers, addonOffers };
    });
    const allBaseOffers = classifiedOffers?.flatMap(offer => offer.baseOffers) || [];
    const allAddonOffers = classifiedOffers?.flatMap(offer => offer.addonOffers) || [];
    const baseOffersWithAddons = allBaseOffers.map(baseOffer => ({
        ...baseOffer,
        addOnOffers: allAddonOffers.filter(addon => addon.subscriptionId === baseOffer.subscriptionId)
    }));
    const getSubscriptionId = (offerCode) => {
        if (Array.isArray(customerData.subscriptionList)) {
            const subscription = customerData.subscriptionList.find(sub => sub.subscribedOffers && sub.subscribedOffers.some(offer => offer.offerCode === offerCode));
            return subscription ? subscription.subscriptionId : null;
        }
        return null;
    }
    return (
        <React.Fragment>
            <ToastContainer position="top-center" />
            {baseOffersWithAddons.length > 0 ? (
                <MaterialReactTable
                    columns={subscriptionColumns}
                    data={baseOffersWithAddons}
                    enableDensityToggle={false}
                    enableColumnOrdering={true}
                    enableExpanding={true}
                    enableRowActions={subscriptionPermission?.permissionList.includes("unsubscribe")}
                    getRowCanExpand={(row) => row.original.addOnOffers && row.original.addOnOffers.length > 0}
                    enableSorting={false}
                    muiTableDetailPanelProps={{
                        sx: {
                            fontFamily: "Inter,sans-serif",
                        },
                    }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: '',
                            enableHiding: true,
                            size: 52,
                        },
                    }}
                    icons={{
                        DragHandleIcon: () => <i className="ri-drag-move-fill ms-3" />,
                    }}
                    renderExpandIcon={({ expanded }) => (
                        <Box
                            component="svg"
                            className={`MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root expand-icon ${expanded ? 'expanded' : ''
                                }`}
                            focusable="false"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                            data-testid="ExpandMoreIcon"
                        >
                            <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                        </Box>
                    )}
                    renderRowActionMenuItems={({ closeMenu, row }) => [
                        subscriptionPermission && subscriptionPermission.permissionList.includes("unsubscribe") ? (
                            <MenuItem disabled={row.original.status === "CANCEL"} key="view" onClick={() => {
                                setMessageForPopUp(`Are you sure you want to cancel this subscription "${row.original.offerName}"?`);
                                showPopUp(row, setSubscriptionId, setOpenPopUp, openPopUp);
                                closeMenu();
                            }}>
                                <i className='ri ri-delete-bin-5-line me-2 text-danger' />
                                Cancel Subscription
                            </MenuItem>
                        ) : null,
                        !row.original.addOnOffer ? (
                            <MenuItem disabled={row.original.status === "CANCEL"} className='row-options' key="subscribe" onClick={() => {
                                const id = getSubscriptionId(row.original.offerCode);
                                navigate(`/add-on/${customerId}`, { state: { subscriptionId: id } });
                            }}>
                                <i className="ri-add-line me-2"></i> Add On
                            </MenuItem>
                        ) : null,
                        !row.original.addOnOffer ? (
                            <MenuItem disabled={row.original.status === "CANCEL"} className='row-options' key="change" onClick={() => {
                                navigate(`/change-offer/${customerId}`, { state: { offerCode: row.original.offerCode, subscriptionIdOfCustomer: row.original.subscriptionId } });
                            }}>
                                <i className="ri-exchange-line me-2"></i> Change Offer
                            </MenuItem>
                        ) : null,
                    ]}
                    renderDetailPanel={({ row }) => (
                        <Box className='me-5'>
                            {row.original.addOnOffers && row.original.addOnOffers.length > 0 ? (
                                <>
                                    <MaterialReactTable
                                        columns={[
                                            {
                                                accessorKey: 'actions',
                                                header: '',
                                                Cell: ({ row }) => (
                                                    <>
                                                        <i
                                                            className='ri-more-2-fill me-2 text-black-50 fs-4 ms-0'
                                                            onClick={handlePopoverOpen}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                        <Popover
                                                            open={isPopoverOpen}
                                                            anchorEl={anchorEl}
                                                            onClose={handlePopoverClose}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'left',
                                                            }}
                                                        >
                                                            <Box p={1} >
                                                                <MenuItem disabled={row.original.status === "CANCEL"} key="view" onClick={() => {
                                                                    setMessageForPopUp(`Are you sure you want to cancel this subscription "${row.original.offerName}"?`);
                                                                    showPopUp(row, setSubscriptionId, setOpenPopUp, openPopUp);
                                                                   
                                                                }}>
                                                                    <i className='ri ri-delete-bin-5-line me-2 text-danger' />
                                                                    Cancel Subscription
                                                                </MenuItem>
                                                            </Box>
                                                        </Popover>
                                                    </>
                                                ),
                                                size: 0,
                                            },
                                            {
                                                accessorKey: 'offerName',
                                                header: 'Offer Name',
                                                size: 200,
                                                Cell: ({ cell }) => (
                                                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '75px' }}>
                                                        {cell.getValue()}
                                                    </Box>
                                                ),
                                            },
                                            {
                                                accessorKey: 'validFrom',
                                                header: 'Offer Starts',
                                                size: 160,
                                                Cell: ({ cell }) => moment(cell.getValue()).format("DD MMM YYYY"),
                                            },
                                            {
                                                accessorKey: 'validTo',
                                                header: 'Offer Ends',
                                                size: 140,
                                                Cell: ({ cell }) => moment(cell.getValue()).format("DD MMM YYYY"),
                                            },
                                            {
                                                accessorKey: 'status',
                                                header: 'Status',
                                                size: 140,
                                                Cell: ({ cell }) => (
                                                    <Box sx={(theme) => (cell.getValue() ? getCellStyle(cell.getValue(), "Station") : {})}>
                                                        {getStatusLabel(cell.getValue())}
                                                    </Box>
                                                ),
                                            },
                                            {
                                                accessorKey: 'addOnOffer',
                                                header: 'Type',
                                                size: 90,
                                                Cell: ({ cell }) => cell.getValue() ? 'Add-On' : 'Base Offer',
                                            }
                                        ]}
                                        data={row.original.addOnOffers}
                                        enableHeader={false}
                                        enableColumnOrdering={true}
                                        enableExpanding={false}
                                        enableDensityToggle={false}
                                        enableTopToolbar={false}
                                        enableBottomToolbar={false}
                                        muiTableHeadCellProps={{
                                            sx: { display: 'none' }  // Hide the header cells
                                        }}
                                        muiTableHeadProps={{
                                            sx: { display: 'none' }  // Hide the header row
                                        }}
                                        icons={{
                                            DragHandleIcon: () => <i className="ri-drag-move-fill ms-3" />,
                                        }}
                                        muiTableBodyProps={{
                                            sx: {
                                                fontFamily: "Inter,sans-serif",
                                            },
                                        }}
                                        displayColumnDefOptions={{
                                            'mrt-row-actions': {
                                                header: '',
                                                enableHiding: true,
                                                size: 0,
                                            },
                                        }}
                                    />   
                                </>
                            ) : (
                                <p className="d-flex justify-content-start ms-5">
                                    <span className="ms-5">No Add-On Offers available for the above Base Offer</span>
                                </p>
                            )}
                        </Box>
                    )}
                />
            ) : null}
            {openPopUp && (
                <CommonModal
                    open={openPopUp}
                    toggle={() => setOpenPopUp(!openPopUp)}
                    messageForPopUp={messageForPopUp}
                    buttonText={"Yes, Cancel It"}
                    modalAction={() => {
                        unsubscribeOffer(customerData, customerId, subscriptionId, setLoading, dispatch);
                        setOpenPopUp(!openPopUp);
                    }}
                />
            )}
            {loading && <Loader />}
        </React.Fragment>
    );
}
