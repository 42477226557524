import { fetchResourceByIdSuccess, fetchResourceByIdFailure } from "./reducer"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { GET_RESOURCE_BY__ID_API } from '../../constants/constants'
import { typevalue } from "../../../Components/Pricings/Resources/constants/Initialvalues";
import { consumptionRules } from "../../../Components/Pricings/Resources/constants/selectoptions";

export const getAllResourcesById = (setLoading, resourceName, initialValues, setThresholds) => (dispatch) => {
    setLoading(true);
    determineBaseUrlAndPerformApiCall(`${GET_RESOURCE_BY__ID_API}/${resourceName}`, 'GET')
        .then((response) => {
            setLoading(false);
            dispatch(fetchResourceByIdSuccess(response));

            initialValues.isCurrency = typevalue?.options?.find(option => option?.value === response?.isCurrency);
            initialValues.name = response?.name;
            initialValues.resourceId = response?.resourceId || "";
            initialValues.currencyCode = { value: response?.currencyCode, label: response?.currencyCode };
            initialValues.ceiling = response?.ceiling?.toString();
            initialValues.floor = response?.floor?.toString();
            initialValues.uom = { value: response?.unitofmeasurement, label: response?.unitofmeasurement };
            initialValues.consumptionRule = consumptionRules?.options?.find(option =>option?.value === response?.consumptionRule)
            initialValues.thresholds = response?.threshold
            initialValues.aggregateResource = response?.aggregates.map((aggregate) => ({
                value: aggregate,
                label: aggregate
            }));

            

            setThresholds(initialValues?.thresholds)

        })
        .catch((errorResp) => {
            dispatch(fetchResourceByIdFailure(errorResp.message));
        });
};



